import { Container } from './styles.js';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from './ThemeProvider.jsx';
import ListPage from './routes/ListPage.jsx';
import { Route, Routes, useLocation } from 'react-router-dom';
import DetailPage from './routes/DetailPage.jsx';
import SignInPage from './routes/SignInPage.jsx';
import FeedPage from './routes/FeedPage.jsx';
import {
  BottomNavigation,
  BottomNavigationAction,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Slide } from '@mui/material';
import { navigationItems } from '../constants/navigationItems.js';
import DrawerLeft from './drawer/DrawerLeft.jsx';
import VMPage from './routes/VMPage.jsx';
import Appbar from './appbar/Appbar.jsx';
import FixedAppbar from './appbar/FixedAppbar.jsx';
import VMActionPage from './routes/VMActionPage.jsx';
import MonitorPage from './routes/MonitorPage.jsx';
import { useSelector } from 'react-redux';
import RevenuePage from './routes/RevenuePage.jsx';
import TopTabView from './tab/TopTabView.jsx';

function MainContainer() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [navIndex, setNavIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });
  const uid = useSelector(state => state.user.uid);
  const isLandscape = windowSize.width > windowSize.height;

  const muiTheme = createTheme({
    components: {
      MuiBottomNavigation: {
        defaultProps: {
          sx: {
            backgroundColor: theme.back_primary,
            color: theme.text_primary,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
          },
        },
      },
    },
  });

  const handleNavChange = (event, newIndex) => {
    if (navigationItems[newIndex].label === 'Hardware' && uid !== '') {
      navigate(`/dashboard/${uid}`);
    } else {
      navigate(navigationItems[newIndex].path);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    navigationItems.map((item, index) => {
      if (navigationItems[index].label === 'Hardware') {
        const itemParts = item.path.split('/', 2);
        const locationParts = location.pathname.split('/', 2);
        if (itemParts.length > 1 && locationParts.length > 1) {
          if (itemParts[1] === locationParts[1]) {
            setNavIndex(index);
          }
        }
      } else {
        if (item.path === location.pathname) {
          setNavIndex(index);
        }
      }
      return null;
    });
  }, [location]);

  return (
    <Container theme={theme} $isdraweropen={isLandscape && location.pathname !== '/'}>
      {isLandscape ? <Appbar /> : <FixedAppbar />}
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/dashboard/:uid" element={<TopTabView />} />
        <Route path="/vms" element={<VMPage />} />
        <Route path="/vmaction" element={<VMActionPage />} />
        <Route path="/graphs" element={<MonitorPage />} />
        <Route path="/home" element={<FeedPage />} />
        <Route path="/revenue" element={<RevenuePage />} />
      </Routes>
      <ThemeProvider theme={muiTheme}>
        {location.pathname !== '/' &&
          (!isLandscape ? (
            <Slide direction="up" in={!isLandscape} mountOnEnter unmountOnExit>
              <BottomNavigation
                value={navIndex}
                onChange={handleNavChange}
                style={{
                  position: 'fixed',
                  bottom: '-2px',
                  left: 0,
                  right: 0,
                  height: '56px',
                }}>
                {navigationItems.map((item, index) => (
                  <BottomNavigationAction
                    key={index}
                    icon={item.icon}
                    label={item.label}
                    sx={{
                      color: theme.text_secondary,
                      '&.Mui-selected': {
                        color: theme.primary,
                      },
                    }}
                  />
                ))}
              </BottomNavigation>
            </Slide>
          ) : (
            <DrawerLeft open={isLandscape} theme={theme} toggleTheme={toggleTheme} />
          ))}
      </ThemeProvider>
    </Container>
  );
}

export default MainContainer;
