import { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider.jsx';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import ActionButton from '../buttons/ActionButton.jsx';
import { Forum } from '@mui/icons-material';

const Container = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 5px;
  color: ${({ theme }) => theme.text_primary};
`;
const FeedContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const FeedTitle = styled.div`
  font-size: 16px;
`;

const FeedAge = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text_secondary};
`;

const TextContent = styled.div`
  font-size: 14px;
  letter-spacing: 0.1rem;
`;

const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;

function FeedItem({ feedData }) {
  const { theme } = useContext(ThemeContext);

  return (
    <Container theme={theme}>
      <Avatar
        src="https://i.pravatar.cc/300"
        alt="User Avatar"
        size="100px"
        border="3px solid #000"
      />
      <FeedContent>
        <Header>
          <FeedTitle>Alice</FeedTitle>
          <FeedAge theme={theme}>3 mins ago</FeedAge>
        </Header>
        <TextContent>
          CPU utilization is slightly above average due to increased load from recent deployments
        </TextContent>
        <Footer>
          <ActionButton
            icon={Forum}
            label="Chat"
            onClick={() => console.log('Chat button clicked')}
            variant="contained"
            color="primary"
          />
          <ActionButton
            label="Share"
            onClick={() => console.log('Share button clicked')}
            variant="contained"
            color="primary"
          />
        </Footer>
      </FeedContent>
    </Container>
  );
}

export default FeedItem;
