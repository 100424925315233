import { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { mock_load1_data } from '../../utils/data';
import { apiRequest } from '../../utils/apirequest';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`;

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const InfoTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
  margin: 5px 0 5px 5px;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    font-size: 18px;
  }
`;

const InfoContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 5px 0 5px 0;
  color: ${({ theme }) => theme.text_primary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// let chartData = [];
let hostnames = [];

const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

function MonitorPage() {
  const { theme } = useContext(ThemeContext);
  const chartRef = useRef(null);
  const data = useSelector(state => state.machines.data);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const chartData = [];
    const fetchLoad1Data = async () => {
      hostnames = Object.values(data.aerosol.aerosolInfos||{})
        .map(value => value.hostname + ':9100')
        .sort();
      const pipeSeparatedHostnames = hostnames.join('|');

      const query = `node_load1{instance=~"${pipeSeparatedHostnames}"}`;
      const range = {
        start: new Date(new Date().getTime() - 60 * 60 * 1000).toISOString(), // 1 hour ago
        end: new Date().toISOString(), // now
        step: 60 * 10 ** 9, // one minute
      };
      const request = {
        macaroons: [data.macaroons.load1],
        queryRanges: [{ query, range }],
      };
      const result = await apiRequest('monitor', 'prometheus', request);
      if (result.error) {
        console.error(result.error);
        return;
      }

      const load1Data = result.response;
      const streams = load1Data.results[0].result.streams||[];
      const newChart = {
        name: `System Load`,
        data: {},
      };
      streams.forEach((stream, host) => {
        const timestamps = stream.values.map(({ timestamp, value }) => new Date(timestamp));
        const values = stream.values.map(({ timestamp, value }) => value);
        newChart.data[hostnames[host]] = { timestamps, values };
      });
      chartData.push(newChart);
      setChartData(chartData);
    };

    const fetchMockData = async () => {
      setTimeout(async () => {
        const response = await Promise.resolve({ json: () => Promise.resolve(mock_load1_data) });
        const load1Data = await response.json();
        const streams = load1Data.results[0].result.streams;
        const newChart = {
          name: `System Load`,
          data: {},
        };
        streams.forEach((stream, host) => {
          const timestamps = stream.values.map(({ timestamp, value }) => new Date(timestamp));
          const values = stream.values.map(({ timestamp, value }) => value);
          newChart.data[hostnames[host]] = { timestamps, values };
        });
        chartData.push(newChart);
        setChartData(chartData);
      }, 300);
    };
    if (isDebugMode) {
      fetchMockData();
    } else {
      fetchLoad1Data();
    }
  }, [data]);

  return (
    <Content>
      {chartData.map(chart => (
        <InfoGroup ref={chartRef}>
          <InfoTitle theme={theme}>{chart.name}</InfoTitle>
          <InfoContent theme={theme}>
            <Row>
              <LineChart
                xAxis={[{ data: chart.data[hostnames[0]]?.timestamps, scaleType: 'utc' }]}
                series={Object.keys(chart.data).map(key => ({
                  data: chart.data[key].values,
                  color: theme.primary,
                  valueFormatter: value => `${key}: ${value}`,
                }))}
                slotProps={{ legend: { hidden: true } }}
                height={300}
                margin={{ top: 20, left: 30, bottom: 30, right: 10 }}
                axisHighlight={{ x: 'none', y: 'none' }}
                tooltip={{ trigger: 'axis' }}
                sx={{
                  '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                    strokeWidth: '0.4',
                    fill: theme.text_secondary,
                  },
                  '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
                    strokeWidth: '0.5',
                    fill: theme.text_secondary,
                  },
                  '& .MuiChartsAxis-bottom .MuiChartsAxis-tick': {
                    stroke: theme.border,
                    strokeWidth: 0.4,
                  },
                  '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
                    stroke: theme.border,
                    strokeWidth: 0.4,
                  },
                  '& .MuiChartsAxis-left .MuiChartsAxis-line': {
                    stroke: theme.border,
                    strokeWidth: 0.4,
                  },
                  '& .MuiChartsAxis-left .MuiChartsAxis-tick': {
                    stroke: theme.border,
                    strokeWidth: 0.4,
                  },
                }}
              />
            </Row>
          </InfoContent>
        </InfoGroup>
      ))}
    </Content>
  );
}
/*
<Button
  variant="contained"
  fullWidth
  sx={{
    backgroundColor: theme.primary,
    '&:hover': { backgroundColor: theme.primary },
    '&.Mui-disabled': {
      background: theme.back_secondary,
      color: theme.text_secondary,
    },
    marginTop: '10px',
  }}
  onClick={addChart}>
  <Add />
</Button>
*/

export default MonitorPage;
