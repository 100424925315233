import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uid: '',
  email: '',
  orderInfo: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUID(state, action) {
      state.uid = action.payload;
    },
    storeEmail(state, action) {
      state.email = action.payload;
    },
    storeOrderInfo(state, action) {
      state.orderInfo = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { storeUID, storeEmail, storeOrderInfo } = userSlice.actions;

export default userSlice.reducer;
