import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const machinesSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    storeMachines(state, action) {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeMachines } = machinesSlice.actions;

export default machinesSlice.reducer;
