import React from 'react';
import styled from 'styled-components';
import { Icon } from '@mui/material';
import { ShareIcon } from '../icons/ShareIcon';

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  cursor: pointer;
`;

const ButtonIcon = styled(Icon)`
  margin-right: 8px;
`;

const ActionButton = ({ icon: IconComponent, label, onClick, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {label === 'Share' ? <ShareIcon /> : <ButtonIcon as={IconComponent} />}
      {label}
    </StyledButton>
  );
};

export default ActionButton;
