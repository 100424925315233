import styled from 'styled-components';
import { drawer_width, fixed_topbar_height } from '../constants/navigationItems';

export const Container = styled.div`
  width: 100%;
  display: flex;
  /* max-width: 1000px; */
  flex-direction: column;
  padding: 5px 3px 5px 3px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.back_primary};
  margin-top: ${({ $isdraweropen }) => ($isdraweropen ? '0' : fixed_topbar_height)};
  margin-left: ${({ $isdraweropen }) => ($isdraweropen ? drawer_width : '0')};
  transition: margin-right 0.3s ease-in-out;

  width: ${({ $isdraweropen }) => ($isdraweropen ? `calc(98% - ${drawer_width})` : '100%')};
`;

export const TopBar = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px 0;
`;

export const Logo = styled.img`
  height: 50%;
`;

export const BackButton = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.text_primary};
`;

export const MachineContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text_primary};
  border-color: ${({ theme }) => theme.border};
`;

export const TextItem = styled.div`
  width: auto;
  height: auto;
  font-size: 14px;
  font-weight: 500;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 12px;
  gap: 10px;
  color: ${({ theme }) => theme.text_secondary};

  @media (min-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 1000px) {
    font-size: 16px;
  }
`;

export const Label1 = styled.div`
  flex: 4;
  text-align: left;
`;

export const Label2 = styled.div`
  flex: 1;
  text-align: left;
`;

export const Label3 = styled.div`
  flex: 2;
  text-align: center;
`;

export const Label4 = styled.div`
  flex: 1;
  text-align: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary};

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    font-size: 18px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
  cursor: pointer;
`;

export const Item1 = styled.div`
  flex: 4;
  text-align: left;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Item2 = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Item3 = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Item4 = styled.div`
  flex: 1;
  text-align: center;
`;

export const CanvasWrapper = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  min-width: ${({ $count }) => $count * 100}px;
  gap: 10px;
  color: ${({ theme }) => theme.text_primary};
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;

  @media (min-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 45px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
