import { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider.jsx';
import {
  MachineContent,
  Item1,
  Item2,
  Item3,
  Item4,
  Row,
  Header,
  Label1,
  Label2,
  Label3,
  Label4,
} from '../styles.js';
import StatusIcon from '../StatusIcon.jsx';
import { useNavigate } from 'react-router-dom';

function MachineGroup({ machines }) {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = monthNames[new Date().getMonth()];

  const handleClick = item => {
    const dataToDetail = {
      product: item.Product,
      item: item,
    };
    navigate('/detail', { state: { data: dataToDetail } });
  };

  return (
    <MachineContent theme={theme}>
      <Header theme={theme}>
        <Label1>Product</Label1>
        <Label3>S/N</Label3>
        <Label4>{month}</Label4>
      </Header>
      {machines.map((machine, index) => (
        <Row onClick={() => handleClick(machine)} key={index}>
          <Item1>{machine.Product}</Item1>
          <Item3>{machine.SerialNumber}</Item3>
          <Item4>$-</Item4>
        </Row>
      ))}
    </MachineContent>
  );
}

export default MachineGroup;
