import styled from 'styled-components';

const IconContainer = styled.div`
  width: 20px;

  @media (min-width: 600px) {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1000px) {
    width: 30px;
    height: 30px;
  }
`;

export const MenuIcon = ({ theme }) => {
  return (
    <IconContainer>
      <svg viewBox="0 0 32 32" fill={theme.text_primary} xmlns="http://www.w3.org/2000/svg">
        <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
      </svg>
    </IconContainer>
  );
};
