import { IconButton } from '@mui/material';
import { AndroidShareIcon } from './AndroidShareIcon';
import { DefaultShareIcon } from './DefaultShareIcon';
import { IOSShareIcon } from './IOSShareIcon';
import { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider';

export const ShareIcon = () => {
  const { theme } = useContext(ThemeContext);

  const userAgent = window.navigator.userAgent;
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  const isAndroid = /Android/i.test(userAgent);

  return (
    <IconButton>
      {isIOS ? (
        <IOSShareIcon theme={theme} />
      ) : isAndroid ? (
        <AndroidShareIcon theme={theme} />
      ) : (
        <DefaultShareIcon theme={theme} />
      )}
    </IconButton>
  );
};
