import styled from 'styled-components';
import VMCard from '../cards/VMCard';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 5px;

  @media (min-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
`;

export default function VMPage() {
  const [vmData, setVMData] = useState([]);
  const data = useSelector(state => state.machines.data);

  useEffect(() => {
    const vms = [];
    if (Object.keys(data).length === 0) return;
    Object.keys(data?.vminfo?.vmInfos||{}).forEach(key => {
      vms.push({ ...data?.vminfo?.vmInfos[key], id: key });
    });
    setVMData(vms);
  }, [data]);
  return (
    <Content>
      {vmData.map((one, i) => (
        <VMCard data={one} key={i} />
      ))}
    </Content>
  );
}
