import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Legend, TextItem } from '../styles';
import StatusIcon from '../StatusIcon';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import MachineGroup from '../cards/MachineGroup';
import { ErrorOutline } from '@mui/icons-material';
import { new_mockup_data } from '../../utils/data';
import { useDispatch } from 'react-redux';
import { storeMachines } from '../../app/slices/machinesSlice';
import { storeEmail, storeUID, storeOrderInfo } from '../../app/slices/userSlice';
import { apiRequest } from '../../utils/apirequest';
import LoadingPage from './LoadingPage';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
`;

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 30px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary};
  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #ddd; */
  /* border-radius: 10px; */
  padding: 5px 10px 5px 10px;
  gap: 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.error};
  /* border-color: ${({ theme }) => theme.error}; */
`;

const ErrorText = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.error};
  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

function ListPage() {
  const { uid } = useParams();
  const [fetchedData, setFetchedData] = useState(null);
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState('');
  const [showList, setShowList] = useState(false);
  const [errorMachineList, setErrorMachineList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getMachineData = data => {
    const machineData = [];
    const errorMachines = [];
    if (!data) return machineData;

    Object.keys(data.aerosol.errors||{}).forEach(ip => {
      errorMachines.push(ip);
    });
    setErrorMachineList(errorMachines);

    Object.keys(data.aerosol.aerosolInfos||{}).forEach(ip => {
      const sysInfo = data.aerosol.aerosolInfos[ip].dmi['System Information'];
      const machine = {
        Product: `${sysInfo['Manufacturer']} ${sysInfo['Product Name']}`,
        Status: 'Deployed',
        ip: ip,
        SerialNumber: sysInfo['Serial Number'],
        Details: data.aerosol.aerosolInfos[ip],
      };
      // pmUsage is indexed by the same set of IP addresses
      machine.pmusage = data.pmusage.pmUsageInfos[ip];
      machineData.push(machine);
    });
    return machineData;
  };

  useEffect(() => {
    setMachines(getMachineData(fetchedData));
  }, [fetchedData]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!isDebugMode) {
        const request = { uid };
        const result = await apiRequest('core', 'dashboard', request);
        if (result.error) {
          setError(`${result.error}`);
          console.error(result.error);
          return;
        }
        if (result.navigate) {
          navigate(result.navigate);
        }
        setError('');
        setFetchedData(result.response);
        dispatch(storeMachines(result.response));
        dispatch(storeEmail(result.response?.email));
        dispatch(storeOrderInfo(result.response?.orderInfo));
        setLoading(false);
      } else {
        setError('');
        setFetchedData(new_mockup_data);
        dispatch(storeMachines(new_mockup_data));
        dispatch(storeEmail('test@example.com'));
        dispatch(
          storeOrderInfo(
            '{"own":[[1234567,"TEST",123456,"2024-02-27T16:47:07","$10,500.00","CloudX 1x",1,"Deployed",390,390,"test@example.com","NOTAREALSN","10.0.16.109","Deployed"]],"lto":null,"coOwn":null}',
          ),
        );
        await new Promise(resolve => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };
    dispatch(storeUID(uid));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  if (loading) return <LoadingPage />;

  return error ? (
    <ErrorContent theme={theme}>{error}</ErrorContent>
  ) : (
    <Container>
      <HeaderGroup>
        <Title theme={theme}>Machines</Title>
        <IconButton sx={{ color: theme.error, padding: '5px' }} onClick={() => setShowList(true)}>
          <ErrorOutline />
        </IconButton>
      </HeaderGroup>
      <MachineGroup machines={machines} />
      <InfoGroup></InfoGroup>
      <Dialog
        open={showList}
        onClose={() => setShowList(false)}
        PaperProps={{ style: { backgroundColor: theme.back_primary } }}>
        <DialogTitle style={{ color: theme.text_secondary }}>Machines with error</DialogTitle>
        <DialogContent style={{ color: theme.text_primary }}>
          <ErrorContent theme={theme}>
            {errorMachineList.map((one, index) => (
              <ErrorText key={index}>{one}</ErrorText>
            ))}
          </ErrorContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowList(false)} style={{ color: theme.text_secondary }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ListPage;
