import React, { useContext } from 'react';
import { ThemeContext } from './ThemeProvider';
import { IconButton } from '@mui/material';
import SunIcon from './icons/SunIcon';
import MoonIcon from './icons/MoonIcon';

export const ThemeToggle = ({ toggled, onChange }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <IconButton onClick={onChange} color="inherit">
      {toggled ? <SunIcon theme={theme} /> : <MoonIcon theme={theme} />}
    </IconButton>
  );
};
