import React, { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { convertTime } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 5px 0 5px 0;
  color: ${({ theme }) => theme.text_primary};
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 0 10px 0 10px;
  font-weight: 600;
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 0 10px;
  font-weight: 400;
  font-size: 14px;
  gap: 10px;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    font-size: 18px;
  }
`;
const Item = styled.div`
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  flex: 1;
`;

const Name = styled.div`
  color: ${({ $isactive, theme }) => ($isactive === 'Active' ? theme.active : theme.inactive)};
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const VMCard = ({ data }) => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/vmaction', { state: { data: data } });
  };

  return (
    <Container theme={theme} onClick={onClick}>
      <NameRow>
        <Name $isactive={data?.status} theme={theme}>
          {data?.instanceName}
        </Name>
      </NameRow>
      <Row>
        <Item>{data?.imageName}</Item>
        <Item>{data?.ipAddress}</Item>
      </Row>
      <Row>
        <Item>{data?.flavor}</Item>
        <Item>{convertTime(data?.age)}</Item>
      </Row>
      <Row>
        <Item>{data?.keyPair}</Item>
        <Item>{data?.task}</Item>
      </Row>
      <Row>
        <Item>{data?.status}</Item>
        <Item>{data?.powerState}</Item>
      </Row>
    </Container>
  );
};

export default VMCard;
