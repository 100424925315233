import { useContext } from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function LoadingPage() {
  const { theme } = useContext(ThemeContext);
  return (
    <Content>
      <GridLoader
        color={theme.primary}
        loading={true}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Content>
  );
}
