import { Computer, Monitor, Storage, Home, AttachMoney } from '@mui/icons-material';

export const navigationItems = [
  { icon: <Home />, label: 'Home', path: '/home' },
  { icon: <AttachMoney />, label: 'Revenue', path: '/revenue' },
  { icon: <Storage />, label: 'Hardware', path: '/dashboard/:uid' },
  { icon: <Computer />, label: 'VMs', path: '/vms' },
  { icon: <Monitor />, label: 'Monitor', path: '/graphs' },
];

export const drawer_width = '200px';
export const topbar_height = '100px';
export const fixed_topbar_height = '50px';
