import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary};
  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const OrderGroup = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text_primary};
  border-color: ${({ theme }) => theme.border};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.text_secondary};

  @media (min-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 1000px) {
    font-size: 16px;
  }
`;

const Label1 = styled.div`
  flex: 1;
  text-align: left;
`;

const Label2 = styled.div`
  flex: 2;
  text-align: center;
`;

const Label3 = styled.div`
  flex: 1;
  text-align: center;
`;

const Label4 = styled.div`
  flex: 1;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
`;

const Item1 = styled.div`
  flex: 1;
  text-align: left;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Item2 = styled.div`
  flex: 2;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Item3 = styled.div`
  flex: 1;
  text-align: center;
`;

const Item4 = styled.div`
  flex: 1;
  text-align: center;
`;

function OrderListPage() {
  const { theme } = useContext(ThemeContext);
  const [machines, setMachines] = useState([]);
  const orderInfo = useSelector(state => {
    try {
      return JSON.parse(state.user.orderInfo);
    } catch (e) {
      return { own: null, lto: null, coOwn: null };
    }
  });

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  
  const addDaysToDate = (date, days) => {
    // Create a new Date object to avoid mutating the original date
    const result = new Date(date); 
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    const allMachines = [];
    if (orderInfo.own) {
      orderInfo.own.forEach(one => {
        const date = new Date(one[3].split(' ')[0]);
        allMachines.push({
          date: formatDate(date),
          product: one[5],
          status: one[7],
          expectedDeploy: formatDate(addDaysToDate(date, 120)),
        });
      });
    }
    if (orderInfo.lto) {
      orderInfo.lto.forEach(one => {
        const date = new Date(one[3].split(' ')[0]);
        allMachines.push({
          date: formatDate(date),
          product: one[5],
          status: one[7],
          expectedDeploy: formatDate(addDaysToDate(date, 120)),
        });
      });
    }
    if (orderInfo.coOwn) {
      orderInfo.coOwn.forEach(one => {
        allMachines.push({
          date: '-',
          product: 'CloudX Co-Own',
          status: one[7],
          expectedDeploy: '-',
        });
      });
    }
    setMachines(allMachines);
  }, [orderInfo]);
  return (
    <Container>
      <HeaderGroup>
        <Title theme={theme}>Orders</Title>
      </HeaderGroup>
      <OrderGroup theme={theme}>
        <Header theme={theme}>
          <Label1>Date</Label1>
          <Label2>Product Description</Label2>
          <Label3>Status</Label3>
          <Label4>Expected deployment date</Label4>
        </Header>
        {machines.map((machine, index) => (
          <Row key={index}>
            <Item1>{machine.date}</Item1>
            <Item2>{machine.product}</Item2>
            <Item3>{machine.status}</Item3>
            <Item4>{machine.expectedDeploy}</Item4>
          </Row>
        ))}
      </OrderGroup>
    </Container>
  );
}

export default OrderListPage;
