export const lightTheme = {
  primary: '#32a2c3',
  secondary: '#909091',
  back_primary: '#fff',
  back_secondary: '#eee',
  text_primary: '#555',
  text_secondary: '#888',
  border: '#444545',
  active: '#10A957',
  inactive: '#444545',
  error: '#e57373',
};

export const darkTheme = {
  primary: '#32a2c3',
  secondary: '#909091',
  back_primary: '#212121',
  back_secondary: '#2e2e2e',
  text_primary: '#ffffff',
  text_secondary: '#909091',
  border: '#444545',
  active: '#10A957',
  inactive: '#444545',
  error: '#e57373',
};
