import { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ShareIcon } from '../icons/ShareIcon';

const DetailHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: ${({ theme }) => theme.text_primary};
`;

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const InfoTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
  margin: 5px 0 5px 5px;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    font-size: 18px;
  }
`;

const InfoContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 5px 0 5px 0;
  color: ${({ theme }) => theme.text_primary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 0 10px;
  font-weight: 400;
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const Left = styled.div`
  flex: 2;
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 40px;
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  flex-direction: row;
`;

const Label = styled.div`
  flex: 1 0 0;
  text-align: left;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Value = styled.div`
  text-align: center;
`;

function DetailPage() {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const orderData = location.state?.data;

  let cpus = 1;

  if (orderData?.item.Details?.cpuinfo?.lscpu) {
    orderData?.item.Details.cpuinfo.lscpu.forEach(cpuItem => {
      if (cpuItem.field === 'CPU(s):') {
        cpus = +cpuItem.data;
      }
    });
  }

  return (
    <>
      <DetailHeader theme={theme}>
        <Row>
          <Left>
            <Label>{orderData?.product}</Label>
          </Left>
          <Right>
            <ShareIcon />
          </Right>
        </Row>
      </DetailHeader>
      <InfoGroup>
        <InfoTitle theme={theme}>Specs</InfoTitle>
        <InfoContent theme={theme}>
          <Row>
            <Label>Hostname</Label>
            <Value>{orderData?.item.Details.hostname}</Value>
          </Row>
          <Row>
            <Label>IP Address</Label>
            <Value>{orderData?.item.ip}</Value>
          </Row>
          <Row>
            <Label>CPUs</Label>
            <Value>{cpus}</Value>
          </Row>
          <Row>
            <Label>Total RAM</Label>
            <Value>{orderData?.item.pmusage.memoryMB}</Value>
          </Row>
          <Row>
            <Label>Free RAM</Label>
            <Value>{orderData?.item.pmusage.freeRamMB} MB</Value>
          </Row>
          <Row>
            <Label>Total Disk</Label>
            <Value>{orderData?.item.pmusage.localGB} GB</Value>
          </Row>
          <Row>
            <Label>Free Disk</Label>
            <Value>{orderData?.item.pmusage.freeDiskGB} GB</Value>
          </Row>
          <Row>
            <Label>Running VMs</Label>
            <Value>{orderData?.item.pmusage.runningVMs}</Value>
          </Row>
          <Row>
            <Label>VCPUs</Label>
            <Value>{orderData?.item.pmusage.vcpus}</Value>
          </Row>
          <Row>
            <Label>VCPUs used</Label>
            <Value>{orderData?.item.pmusage.vcpusUsed}</Value>
          </Row>
        </InfoContent>
      </InfoGroup>
      {/* <InfoGroup>
        <InfoTitle theme={theme}>Status</InfoTitle>
        <InfoContent theme={theme}>
          <Row>
            <Label>Status</Label>
            <Value>-</Value>
          </Row>
        </InfoContent>
      </InfoGroup>
      <InfoGroup>
        <InfoTitle theme={theme}>Utilization</InfoTitle>
        <InfoContent theme={theme}>
          <Row>
            <Label>Resource Allocation</Label>
            <Value>-</Value>
          </Row>
          <Row>
            <Label>Actual Usage</Label>
            <Value>-</Value>
          </Row>
        </InfoContent>
      </InfoGroup>
      <InfoGroup>
        <InfoTitle theme={theme}>Services</InfoTitle>
        <InfoContent theme={theme}>
          <Row>
            <Label>Service1</Label>
            <Value>-</Value>
          </Row>
          <Row>
            <Label>Service2</Label>
            <Value>-</Value>
          </Row>
        </InfoContent>
      </InfoGroup>
      <InfoGroup>
        <InfoTitle theme={theme}>Month’s Revenue</InfoTitle>
        <InfoContent theme={theme}>
          <Row>
            <Label>Revenue</Label>
            <Value>-</Value>
          </Row>
          <Row>
            <LineChartComponent />
          </Row>
        </InfoContent>
      </InfoGroup>
      <InfoGroup>
        <InfoTitle theme={theme}>Projected Month's Revenue</InfoTitle>
        <InfoContent theme={theme}>
          <Row>
            <Label>Revenue</Label>
            <Value>-</Value>
          </Row>
          <Row>
            <AreaChartComponent />
          </Row>
        </InfoContent>
      </InfoGroup> */}
    </>
  );
}

export default DetailPage;
