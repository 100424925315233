import { useContext, useState } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { PlayArrow, Replay, Stop } from '@mui/icons-material';
import { IconButton, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiRequest } from '../../utils/apirequest';
import PulseLoader from 'react-spinners/PulseLoader';

const Content = styled.div`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.text_primary};
`;
const VMHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.div`
  text-align: center;
`;

const VMContent = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 10px 0 10px 0;
  color: ${({ theme }) => theme.text_primary};
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

async function rebootVM(vmID, macaroon) {
  const request = {
    macaroons: [macaroon],
    vmid: vmID,
  };
  const result = await apiRequest('compute', 'vmreboot', request);
  if (result.error) {
    console.error(`${result.error}`);
    return false;
  }
  return true;
}

const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

export default function VMActionPage() {
  const { theme } = useContext(ThemeContext);
  const [isPlaying, setIsPlaying] = useState(true);
  const [msgDialog, setMsgDialog] = useState(false);
  const [msg, setMsg] = useState('');
  const location = useLocation();
  const data = location.state?.data;
  const macaroons = useSelector(state => state.machines.data.macaroons);

  return (
    <Content theme={theme}>
      <VMHeader>
        <Name>{data?.instanceName}</Name>
        <Actions>
          <IconButton
            sx={{ color: theme.text_primary }}
            onClick={() => {
              setMsg('Stopping and starting is not yet supported.');
              setMsgDialog(true);
              setIsPlaying(!isPlaying);
            }}>
            {isPlaying ? <Stop /> : <PlayArrow />}
          </IconButton>
          <IconButton
            sx={{ color: theme.text_primary }}
            onClick={async () => {
              setMsg('Rebooting');
              setMsgDialog(true);

              let result = false;
              if (!isDebugMode) result = await rebootVM(data.id, macaroons.vmreboot);
              else await new Promise(resolve => setTimeout(resolve, 2000));

              setMsg(result ? 'Success!' : 'Failed');
              if (!msgDialog) setMsgDialog(true);
            }}>
            <Replay />
          </IconButton>
        </Actions>
      </VMHeader>
      <VMContent theme={theme}>
        <Row></Row>
      </VMContent>
      <Dialog
        open={msgDialog}
        onClose={() => setMsgDialog(false)}
        PaperProps={{
          style: { backgroundColor: theme.back_primary, minHeight: 100, minWidth: 200 },
        }}>
        <DialogContent
          style={{
            color: theme.text_primary,
            alignSelf: 'center',
          }}>
          {msg}
          {msg.includes('Rebooting') && (
            <PulseLoader
              color={theme.primary}
              loading={true}
              size={5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMsgDialog(false)} style={{ color: theme.primary }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
}
