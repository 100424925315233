const config = {
  api: {
    scheme: process.env.REACT_APP_API_SCHEME,
    compute: process.env.REACT_APP_API_COMPUTE,
    hardware: process.env.REACT_APP_API_HARDWARE,
    monitor: process.env.REACT_APP_API_MONITOR,
    core: process.env.REACT_APP_API_CORE,
  },
};

export const apiRequest = async (api, method, request) => {
  let url, httpMethod;
  switch (api) {
  default:
    return {error: new Error(`unrecognized api: ${api}`)};
  case 'compute':
    url = `${config.api.scheme}://${config.api.compute}/compute/v0.2/${method}`;
    switch (method) {
    default:
      return {error: new Error(`unrecognized API method: ${method}`)};
    case 'vminfo':
    case 'pmusage':
      httpMethod = 'GET';
      break;
    case 'vmreboot':
      httpMethod = 'POST';
    }
    break;
  case 'hardware':
    url = `${config.api.scheme}://${config.api.hardware}/hardware/v0.2/${method}`;
    switch (method) {
    default:
      return {error: new Error(`unrecognized API method: ${method}`)};
    case 'aerosol':
      httpMethod = 'GET';
      break;
    }
    break;
  case 'monitor':
    url = `${config.api.scheme}://${config.api.monitor}/monitor/v0.1/${method}`;
    switch (method) {
    default:
      return {error: new Error(`unrecognized API method: ${method}`)};
    case 'prometheus':
      httpMethod = 'GET';
      break;
    }
    break;
  case 'core':
    url = `${config.api.scheme}://${config.api.core}/core/v0.2/${method}`;
    switch (method) {
    default:
      return {error: new Error(`unrecognized API method: ${method}`)};
    case 'dashboard':
    case 'guilogin':
    case 'validuser':
      httpMethod = 'GET';
    }
    break;
  }
  
  if (httpMethod === 'GET') {
    try {
      const encodedRequest = btoa(JSON.stringify(request));
      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            'IN8API-Payload': encodedRequest,
          },
        },
      );

      if (!response.ok) {
        const text = await response.text();
        // If the logindata from the link expired, redirect to the login screen.
        // TODO: do this right.  See https://git.pyro.cloud/eng/in8api/core/-/issues/10
        if (text.indexOf('redis: nil') > -1) {
          return {navigate: '/'};
        }
        return {error: new Error(`HTTP error! status: ${response.status}\n${text}`)};
      }
      const json = await response.json();
      return {response: json};
    } catch (error) {
      return {error};
    }
  } else {
    try {
      const response = await fetch(
        url, 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request),
        }
      );

      if (!response.ok) {
        const text = await response.text();
        return {error: new Error(`HTTP error! status: ${response.status}\n${text}`)};
      }

      const json = await response.json();
      return {response: json};
    } catch (error) {
      return {error};
    }
  }
};  