import { AppBar, IconButton, Menu } from '@mui/material';
import { MenuContainer } from '../styles';
import BackIcon from '../icons/BackIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { ThemeToggle } from '../ThemeToggle';
import { ShareIcon } from '../icons/ShareIcon';
import { useContext, useState } from 'react';
import { ThemeContext } from '../ThemeProvider';
import { darkTheme } from '../../utils/theme';
import DarkLogo from '../../logo_dark_horizontal.png';
import LightLogo from '../../logo_light_horizontal.png';
import styled from 'styled-components';

const Logo = styled.img`
  height: 70%;
`;

const TopBar = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function FixedAppbar() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBackClick = () => {
    window.history.back();
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.back_primary, top: '-1px' }}>
      <TopBar>
        <IconButton onClick={handleBackClick}>
          <BackIcon theme={theme} />
        </IconButton>
        <Logo src={theme === darkTheme ? DarkLogo : LightLogo}></Logo>
        <IconButton size="large" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
          <MenuIcon theme={theme} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiMenu-paper': {
              backgroundColor: `${theme.back_secondary}`,
              color: `${theme.text_primary}`,
              borderRadius: '20px',
            },
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}>
          <MenuContainer>
            <ThemeToggle toggled={theme === darkTheme} onChange={toggleTheme} />
            <ShareIcon />
          </MenuContainer>
        </Menu>
      </TopBar>
    </AppBar>
  );
}
