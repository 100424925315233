import {
  TextField,
  Button,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../ThemeProvider';
import { emailValidator } from '../../utils/validators';
import { useSearchParams } from 'react-router-dom';
import { apiRequest } from '../../utils/apirequest';

const Container = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SignInForm = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

function SignInPage() {
  const { theme } = useContext(ThemeContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isChecking, setIsChecking] = useState(false);
  const [isEmailExisting, setIsEmailExisting] = useState(null);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const customTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ' &:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.primary,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: theme.error,
          },
        },
      },
    },
  });

  const updateStatus = async newEmail => {
    const validationResult = emailValidator(newEmail);
    setIsValid(validationResult);
    setError(validationResult);

    if (validationResult) return;
    setIsChecking(true);

    // Check if the email exists in the database
    const emailExists = await checkEmailInDatabase(newEmail);

    setIsEmailExisting(emailExists);
    setIsChecking(false);
  };

  const handleEmailChange = event => {
    const newEmail = event.target.value;
    setEmailAddress(newEmail);
  };

  const prepareAndSendRequest = async (endpoint, email) => {
    const request = {
      macaroons: [],
      emailAddress: email,
    };
    const result = await apiRequest('core', endpoint, request);
    if (result.error) {
      console.error(`Error in ${endpoint} request: ${result.error}`);
      return { success: false, data: null };
    }
    return { success: true, data: result.response };
  };

  const checkEmailInDatabase = async email => {
    const { success, data } = await prepareAndSendRequest('validuser', email);
    return success && data.valid;
  };

  const handleClick = async () => {
    if (isEmailExisting) {
      const { success, data } = await prepareAndSendRequest('guilogin', emailAddress);
      if (success && data.sentEmail) {
        setSuccessDialog(true);
      } else {
        setErrorDialog(true);
      }
    }
    // TODO: Decide what page to redirect to
    // window.location.href = '...';
  };

  useEffect(() => {
    const emailParam = searchParams.get('email');
    if (emailParam) {
      setEmailAddress(emailParam);
    }
  }, [searchParams]);

  useEffect(() => {
    updateStatus(emailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress]);

  return (
    <Container>
      <SignInForm>
        <ThemeProvider theme={customTheme}>
          <TextField
            label="Email Address"
            type="email"
            value={emailAddress}
            onChange={handleEmailChange}
            helperText={error}
            fullWidth
            required
            sx={{
              '& .MuiInputBase-input': {
                color: theme.text_primary,
              },
              '& label.MuiFormLabel-root': {
                color: theme.primary,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.primary,
              },
              '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: theme.primary,
                },
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={!!isValid || !!isChecking || !isEmailExisting}
            fullWidth
            sx={{
              backgroundColor: theme.primary,
              '&:hover': { backgroundColor: theme.primary },
              '&.Mui-disabled': {
                background: theme.back_secondary,
                color: theme.text_secondary,
              },
            }}
            onClick={handleClick}>
            {/*isChecking ? 'Checking...' : isEmailExisting ? 'Send Login Link' : 'Sign Up'*/ ''}
            Send Login Link
          </Button>
          <Dialog
            open={successDialog}
            onClose={() => setSuccessDialog(false)}
            PaperProps={{ style: { backgroundColor: theme.back_primary } }}>
            <DialogContent style={{ color: theme.text_primary }}>
              A link has been sent to your email address.
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSuccessDialog(false)} style={{ color: theme.primary }}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={errorDialog}
            onClose={() => setErrorDialog(false)}
            PaperProps={{ style: { backgroundColor: theme.back_primary } }}>
            <DialogContent style={{ color: theme.text_primary }}>
              Failed to send a link to the email.
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErrorDialog(false)} style={{ color: theme.primary }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </SignInForm>
    </Container>
  );
}

export default SignInPage;
