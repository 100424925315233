import React from 'react';
import MainContainer from './components/MainContainer';
import { ThemeProvider } from './components/ThemeProvider';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './app/store';
import { Provider } from 'react-redux';
import './App.css';
import { PersistGate } from 'redux-persist/integration/react';

const AppContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider>
            <AppContainer>
              <MainContainer />
            </AppContainer>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
