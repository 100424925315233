import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './ThemeProvider';

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 2px;
  background-color: ${({ color }) => color};
  @media (min-width: 600px) {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
`;

const StatusIcon = ({ status }) => {
  const { theme } = useContext(ThemeContext);

  const statusColors = {
    Deployed: theme.primary,
    Ordered: theme.secondary,
    Unknown: '#491b06',
  };

  const statusLetter = status === 'Deployed' ? 'D' : status === 'Ordered' ? 'O' : '?';
  return <Circle color={statusColors[status]}>{statusLetter}</Circle>;
};

export default StatusIcon;
