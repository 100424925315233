export const chart_data = [
  { date: '1', value: 10 },
  { date: '2', value: 20 },
  { date: '3', value: 30 },
  { date: '4', value: 40 },
  { date: '5', value: 25 },
  { date: '6', value: 10 },
  { date: '7', value: 20 },
  { date: '8', value: 30 },
  { date: '9', value: 40 },
  { date: '10', value: 25 },
];

export const vm_data = [
  {
    'Instance Name': 'a4-0-1',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.101',
    Flavor: 'm1.small',
    'Key Pair': '23211923791273921831',
    Status: 'Active',
    Task: 'None',
    'Power State': 'On',
    Age: 12341,
  },
  {
    'Instance Name': 'a4-0-2',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.102',
    Flavor: 'm1.small',
    'Key Pair': '2321192379127392183',
    Status: 'Active',
    Task: 'None',
    'Power State': 'On',
    Age: 12638267121,
  },
  {
    'Instance Name': 'a4-0-3',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.103',
    Flavor: 'm1.small',
    'Key Pair': '1232323',
    Status: 'Inactive',
    Task: 'None',
    'Power State': 'On',
    Age: 1231231,
  },
  {
    'Instance Name': 'a4-0-3',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.103',
    Flavor: 'm1.small',
    'Key Pair': '1232323',
    Status: 'Active',
    Task: 'None',
    'Power State': 'On',
    Age: 1231231,
  },
  {
    'Instance Name': 'a4-0-3',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.103',
    Flavor: 'm1.small',
    'Key Pair': '1232323',
    Status: 'Active',
    Task: 'None',
    'Power State': 'On',
    Age: 1231231,
  },
  {
    'Instance Name': 'a4-0-3',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.103',
    Flavor: 'm1.small',
    'Key Pair': '1232323',
    Status: 'Inactive',
    Task: 'None',
    'Power State': 'On',
    Age: 1231231,
  },
  {
    'Instance Name': 'a4-0-3',
    'Image Name': 'centOS-9',
    'IP Address': '10.0.16.103',
    Flavor: 'm1.small',
    'Key Pair': '1232323',
    Status: 'Active',
    Task: 'None',
    'Power State': 'On',
    Age: 1231231,
  },
];

export const new_mockup_data = {
  macaroons: [],
  aerosol: {
    aerosolInfos: {
      '10.0.16.102': {
        cpuinfo: {
          lscpu: [
            {
              children: [
                {
                  data: '32-bit, 64-bit',
                  field: 'CPU op-mode(s):',
                },
                {
                  data: '48 bits physical, 48 bits virtual',
                  field: 'Address sizes:',
                },
                {
                  data: 'Little Endian',
                  field: 'Byte Order:',
                },
              ],
              data: 'x86_64',
              field: 'Architecture:',
            },
            {
              children: [
                {
                  data: '0-31',
                  field: 'On-line CPU(s) list:',
                },
              ],
              data: '32',
              field: 'CPU(s):',
            },
            {
              children: [
                {
                  data: 'Advanced Micro Devices, Inc.',
                  field: 'BIOS Vendor ID:',
                },
                {
                  children: [
                    {
                      data: 'AMD Ryzen 9 7950X 16-Core Processor             Unknown CPU @ 4.5GHz',
                      field: 'BIOS Model name:',
                    },
                    {
                      data: '107',
                      field: 'BIOS CPU family:',
                    },
                    {
                      data: '25',
                      field: 'CPU family:',
                    },
                    {
                      data: '97',
                      field: 'Model:',
                    },
                    {
                      data: '2',
                      field: 'Thread(s) per core:',
                    },
                    {
                      data: '16',
                      field: 'Core(s) per socket:',
                    },
                    {
                      data: '1',
                      field: 'Socket(s):',
                    },
                    {
                      data: '2',
                      field: 'Stepping:',
                    },
                    {
                      data: 'enabled',
                      field: 'Frequency boost:',
                    },
                    {
                      data: '67%',
                      field: 'CPU(s) scaling MHz:',
                    },
                    {
                      data: '5879.8818',
                      field: 'CPU max MHz:',
                    },
                    {
                      data: '3000.0000',
                      field: 'CPU min MHz:',
                    },
                    {
                      data: '8983.21',
                      field: 'BogoMIPS:',
                    },
                    {
                      data: 'fpu vme de pse tsc msr pae mce cx8 apic sep mtrr pge mca cmov pat pse36 clflush mmx fxsr sse sse2 ht syscall nx mmxext fxsr_opt pdpe1gb rdtscp lm constant_tsc rep_good amd_lbr_v2 nopl nonstop_tsc cpuid extd_apicid aperfmperf rapl pni pclmulqdq monitor ssse3 fma cx16 sse4_1 sse4_2 x2apic movbe popcnt aes xsave avx f16c rdrand lahf_lm cmp_legacy svm extapic cr8_legacy abm sse4a misalignsse 3dnowprefetch osvw ibs skinit wdt tce topoext perfctr_core perfctr_nb bpext perfctr_llc mwaitx cpb cat_l3 cdp_l3 hw_pstate ssbd mba perfmon_v2 ibrs ibpb stibp vmmcall fsgsbase bmi1 avx2 smep bmi2 erms invpcid cqm rdt_a avx512f avx512dq rdseed adx smap avx512ifma clflushopt clwb avx512cd sha_ni avx512bw avx512vl xsaveopt xsavec xgetbv1 xsaves cqm_llc cqm_occup_llc cqm_mbm_total cqm_mbm_local avx512_bf16 clzero irperf xsaveerptr rdpru wbnoinvd cppc arat npt lbrv svm_lock nrip_save tsc_scale vmcb_clean flushbyasid decodeassists pausefilter pfthreshold avic v_vmsave_vmload vgif x2avic v_spec_ctrl avx512vbmi umip pku ospke avx512_vbmi2 gfni vaes vpclmulqdq avx512_vnni avx512_bitalg avx512_vpopcntdq rdpid overflow_recov succor smca fsrm flush_l1d',
                      field: 'Flags:',
                    },
                  ],
                  data: 'AMD Ryzen 9 7950X 16-Core Processor',
                  field: 'Model name:',
                },
              ],
              data: 'AuthenticAMD',
              field: 'Vendor ID:',
            },
            {
              children: [
                {
                  data: 'AMD-V',
                  field: 'Virtualization:',
                },
              ],
              data: null,
              field: 'Virtualization features:',
            },
            {
              children: [
                {
                  data: '512 KiB (16 instances)',
                  field: 'L1d:',
                },
                {
                  data: '512 KiB (16 instances)',
                  field: 'L1i:',
                },
                {
                  data: '16 MiB (16 instances)',
                  field: 'L2:',
                },
                {
                  data: '64 MiB (2 instances)',
                  field: 'L3:',
                },
              ],
              data: null,
              field: 'Caches (sum of all):',
            },
            {
              children: [
                {
                  data: '1',
                  field: 'NUMA node(s):',
                },
                {
                  data: '0-31',
                  field: 'NUMA node0 CPU(s):',
                },
              ],
              data: null,
              field: 'NUMA:',
            },
            {
              children: [
                {
                  data: 'Not affected',
                  field: 'Gather data sampling:',
                },
                {
                  data: 'Not affected',
                  field: 'Itlb multihit:',
                },
                {
                  data: 'Not affected',
                  field: 'L1tf:',
                },
                {
                  data: 'Not affected',
                  field: 'Mds:',
                },
                {
                  data: 'Not affected',
                  field: 'Meltdown:',
                },
                {
                  data: 'Not affected',
                  field: 'Mmio stale data:',
                },
                {
                  data: 'Not affected',
                  field: 'Retbleed:',
                },
                {
                  data: 'Mitigation; safe RET, no microcode',
                  field: 'Spec rstack overflow:',
                },
                {
                  data: 'Mitigation; Speculative Store Bypass disabled via prctl',
                  field: 'Spec store bypass:',
                },
                {
                  data: 'Mitigation; usercopy/swapgs barriers and __user pointer sanitization',
                  field: 'Spectre v1:',
                },
                {
                  data: 'Mitigation; Retpolines, IBPB conditional, IBRS_FW, STIBP always-on, RSB filling, PBRSB-eIBRS Not affected',
                  field: 'Spectre v2:',
                },
                {
                  data: 'Not affected',
                  field: 'Srbds:',
                },
                {
                  data: 'Not affected',
                  field: 'Tsx async abort:',
                },
              ],
              data: null,
              field: 'Vulnerabilities:',
            },
          ],
        },
        dmi: {
          '': '',
          '# dmidecode 3.4': '',
          '32-bit Memory Error Information': {
            'Device Address': 'Unknown',
            Granularity: 'Unknown',
            'Memory Array Address': 'Unknown',
            Operation: 'Unknown',
            Resolution: 'Unknown',
            Type: 'OK',
            'Vendor Syndrome': 'Unknown',
          },
          'Additional Information 1': {
            'Referenced Handle': '0x0014',
            'Referenced Offset': '0x00',
            String: 'Not Specified',
            Value: 'Unexpected size',
          },
          'BIOS Information': {
            Address: '0xF0000',
            'BIOS Revision': '5.26',
            Characteristics: {
              '3.5"/2.88 MB floppy services are supported (int 13h)': '',
              '3.5"/720 kB floppy services are supported (int 13h)': '',
              '5.25"/1.2 MB floppy services are supported (int 13h)': '',
              '5.25"/360 kB floppy services are supported (int 13h)': '',
              'ACPI is supported': '',
              'BIOS ROM is socketed': '',
              'BIOS boot specification is supported': '',
              'BIOS is upgradeable': '',
              'BIOS shadowing is allowed': '',
              'Boot from CD is supported': '',
              'CGA/mono video services are supported (int 10h)': '',
              'EDD is supported': '',
              'Japanese floppy for NEC 9800 1.2 MB is supported (int 13h)': '',
              'Japanese floppy for Toshiba 1.2 MB is supported (int 13h)': '',
              'PCI is supported': '',
              'Print screen service is supported (int 5h)': '',
              'Printer services are supported (int 17h)': '',
              'Selectable boot is supported': '',
              'Serial services are supported (int 14h)': '',
              'Targeted content distribution is supported': '',
              'UEFI is supported': '',
              'USB legacy is supported': '',
            },
            'ROM Size': '32 MB',
            'Release Date': '10/30/2023',
            'Runtime Size': '64 kB',
            Vendor: 'American Megatrends International, LLC.',
            Version: '1.1',
          },
          'Base Board Information': {
            'Asset Tag': 'To be filled by O.E.M.',
            'Chassis Handle': '0x0003',
            'Contained Object Handles': '0',
            Features: {
              'Board is a hosting board': '',
              'Board is replaceable': '',
            },
            'Location In Chassis': 'Node 1',
            Manufacturer: 'Supermicro',
            'Product Name': 'H13SRD-F',
            'Serial Number': 'OD241S600332',
            Type: 'Motherboard',
            Version: '1.00',
          },
          'Cache Information': {
            Associativity: '16-way Set-associative',
            Configuration: 'Enabled, Not Socketed, Level 3',
            'Error Correction Type': 'Multi-bit ECC',
            'Installed SRAM Type': 'Pipeline Burst',
            'Installed Size': '64 MB',
            Location: 'Internal',
            'Maximum Size': '64 MB',
            'Operational Mode': 'Write Back',
            'Socket Designation': 'L3 Cache',
            Speed: '1 ns',
            'Supported SRAM Types': {
              'Pipeline Burst': '',
            },
            'System Type': 'Unified',
          },
          'Chassis Information': {
            'Asset Tag': 'Chassis Asset Tag',
            'Boot-up State': 'Safe',
            'Contained Elements': '0',
            Height: 'Unspecified',
            Lock: 'Not Present',
            Manufacturer: 'Supermicro',
            'Number Of Power Cords': '1',
            'OEM Information': '0x00000000',
            'Power Supply State': 'Safe',
            'SKU Number': 'To be filled by O.E.M.',
            'Security Status': 'None',
            'Serial Number': 'C9380AM52A20087',
            'Thermal State': 'Safe',
            Type: 'Other',
            Version: '0123456789',
          },
          'End Of Table': '',
          'Firmware Inventory Information': {
            'Associated Components': {
              '0x0016': '',
              _value: '1',
            },
            Characteristics: {
              Updatable: 'No',
              'Write-Protect': 'No',
            },
            'Firmware Component Name': 'BMC Firmware',
            'Firmware ID': 'Not Specified',
            'Firmware Version': '1.01',
            'Image Size': 'Unknown',
            'Lowest Supported Firmware Version': 'Not Specified',
            Manufacturer: '2A7C',
            'Release Date': 'Not Specified',
            State: 'Enabled',
          },
          'Getting SMBIOS data from sysfs.': '',
          'Handle 0x0000, DMI type 0, 26 bytes': '',
          'Handle 0x0001, DMI type 1, 27 bytes': '',
          'Handle 0x0002, DMI type 2, 15 bytes': '',
          'Handle 0x0003, DMI type 3, 22 bytes': '',
          'Handle 0x0004, DMI type 8, 9 bytes': '',
          'Handle 0x0005, DMI type 8, 9 bytes': '',
          'Handle 0x0006, DMI type 8, 9 bytes': '',
          'Handle 0x0007, DMI type 8, 9 bytes': '',
          'Handle 0x0008, DMI type 9, 17 bytes': '',
          'Handle 0x000A, DMI type 9, 17 bytes': '',
          'Handle 0x000B, DMI type 9, 17 bytes': '',
          'Handle 0x000C, DMI type 11, 5 bytes': '',
          'Handle 0x000D, DMI type 32, 20 bytes': '',
          'Handle 0x0012, DMI type 39, 22 bytes': '',
          'Handle 0x0013, DMI type 39, 22 bytes': '',
          'Handle 0x0014, DMI type 41, 11 bytes': '',
          'Handle 0x0015, DMI type 44, 9 bytes': '',
          'Handle 0x0016, DMI type 38, 18 bytes': '',
          'Handle 0x0017, DMI type 45, 26 bytes': '',
          'Handle 0x0022, DMI type 43, 31 bytes': '',
          'Handle 0x0023, DMI type 7, 27 bytes': '',
          'Handle 0x0024, DMI type 7, 27 bytes': '',
          'Handle 0x0025, DMI type 7, 27 bytes': '',
          'Handle 0x0026, DMI type 4, 48 bytes': '',
          'Handle 0x0027, DMI type 18, 23 bytes': '',
          'Handle 0x0028, DMI type 16, 23 bytes': '',
          'Handle 0x0029, DMI type 19, 31 bytes': '',
          'Handle 0x002A, DMI type 17, 92 bytes': '',
          'Handle 0x002B, DMI type 20, 35 bytes': '',
          'Handle 0x002C, DMI type 17, 92 bytes': '',
          'Handle 0x002D, DMI type 20, 35 bytes': '',
          'Handle 0x002E, DMI type 17, 92 bytes': '',
          'Handle 0x002F, DMI type 20, 35 bytes': '',
          'Handle 0x0030, DMI type 17, 92 bytes': '',
          'Handle 0x0031, DMI type 20, 35 bytes': '',
          'Handle 0x0032, DMI type 199, 5 bytes': '',
          'Handle 0x0033, DMI type 40, 203 bytes': '',
          'Handle 0x0034, DMI type 40, 203 bytes': '',
          'Handle 0x0035, DMI type 40, 27 bytes': '',
          'Handle 0x0036, DMI type 40, 27 bytes': '',
          'Handle 0x0037, DMI type 40, 27 bytes': '',
          'Handle 0x0038, DMI type 42, 122 bytes': '',
          'Handle 0x0039, DMI type 127, 4 bytes': '',
          'IPMI Device Information': {
            'Base Address': '0x0000000000000CA2 (I/O)',
            'I2C Slave Address': '0x10',
            'Interface Type': 'KCS (Keyboard Control Style)',
            'NV Storage Device': 'Not Present',
            'Register Spacing': 'Successive Byte Boundaries',
            'Specification Version': '2.0',
          },
          'Management Controller Host Interface': {
            'Device Type': 'USB',
            'Host Interface Type': 'Network',
            'Protocol ID': {
              'Host IP Address Format': 'IPv4',
              'Host IP Assignment Type': 'AutoConf',
              'IPv4 Address': '169.254.3.1',
              'IPv4 Mask': '255.255.255.0',
              'IPv4 Redfish Service Address': '169.254.3.254',
              'IPv4 Redfish Service Mask': '255.255.255.0',
              'Redfish Service Hostname': '169.254.3.254',
              'Redfish Service IP Address Format': 'IPv4',
              'Redfish Service IP Discovery Type': 'AutoConf',
              'Redfish Service Port': '443',
              'Redfish Service Vlan': '0',
              'Service UUID': 'c95cab2a-6de0-45f4-8b3b-726c3794b26b',
              _value: '04 (Redfish over IP)',
            },
            idProduct: '0x03ee',
            idVendor: '0x0b1f',
          },
          'Memory Array Mapped Address': {
            'Ending Address': '0x01FFFFFFFFF',
            'Partition Width': '4',
            'Physical Array Handle': '0x0028',
            'Range Size': '128 GB',
            'Starting Address': '0x00000000000',
          },
          'Memory Device': {
            'Array Handle': '0x0028',
            'Asset Tag': 'DIMMB2_AssetTag (date:23/52)',
            'Bank Locator': 'P0_Node0_Channel1_Dimm1',
            'Cache Size': 'None',
            'Configured Memory Speed': '3600 MT/s',
            'Configured Voltage': '1.1 V',
            'Data Width': '64 bits',
            'Error Information Handle': 'No Error',
            'Firmware Version': 'Unknown',
            'Form Factor': 'DIMM',
            Locator: 'DIMMB2',
            'Logical Size': 'None',
            Manufacturer: 'SK Hynix',
            'Maximum Voltage': '1.1 V',
            'Memory Operating Mode Capability': 'Volatile memory',
            'Memory Subsystem Controller Manufacturer ID': 'Unknown',
            'Memory Subsystem Controller Product ID': 'Unknown',
            'Memory Technology': 'DRAM',
            'Minimum Voltage': '1.1 V',
            'Module Manufacturer ID': 'Bank 1, Hex 0xAD',
            'Module Product ID': 'Unknown',
            'Non-Volatile Size': 'None',
            'Part Number': 'HMCG88MEBEA081N',
            Rank: '2',
            'Serial Number': '80AD012352469EA638',
            Set: 'None',
            Size: '32 GB',
            Speed: '4800 MT/s',
            'Total Width': '72 bits',
            Type: 'DDR5',
            'Type Detail': 'Synchronous Unbuffered (Unregistered)',
            'Volatile Size': '32 GB',
          },
          'Memory Device Mapped Address': {
            'Ending Address': '0x01FFFFFFFFF',
            'Interleave Position': 'Unknown',
            'Interleaved Data Depth': 'Unknown',
            'Memory Array Mapped Address Handle': '0x0029',
            'Partition Row Position': 'Unknown',
            'Physical Device Handle': '0x0030',
            'Range Size': '64 GB',
            'Starting Address': '0x01000000000',
          },
          'OEM Strings': {
            'String 1': 'AMD AM5',
            'String 2': 'Supermicro motherboard-H13 Series',
          },
          'OEM-specific Type': {
            'Header and Data': {
              'C7 05 32 00 01': '',
            },
            Strings: {
              '': '',
            },
          },
          'Onboard Device': {
            'Bus Address': '0000:06:00.0',
            'Reference Designation': 'ASPEED Video AST2600',
            Status: 'Enabled',
            Type: 'Video',
            'Type Instance': '1',
          },
          'Physical Memory Array': {
            'Error Correction Type': 'Other',
            'Error Information Handle': '0x0027',
            Location: 'System Board Or Motherboard',
            'Maximum Capacity': '128 GB',
            'Number Of Devices': '4',
            Use: 'System Memory',
          },
          'Port Connector Information': {
            'External Connector Type': 'USB Type-C Receptacle',
            'External Reference Designator': 'USB2(3.0)',
            'Internal Connector Type': 'None',
            'Internal Reference Designator': 'JUSB3P1',
            'Port Type': 'USB',
          },
          'Processor Information': {
            'Asset Tag': 'Unknown',
            Characteristics: {
              '64-bit capable': '',
              'Enhanced Virtualization': '',
              'Execute Protection': '',
              'Hardware Thread': '',
              'Multi-Core': '',
              'Power/Performance Control': '',
            },
            'Core Count': '16',
            'Core Enabled': '16',
            'Current Speed': '4500 MHz',
            'External Clock': '100 MHz',
            Family: 'Zen',
            Flags: {
              'APIC (On-chip APIC hardware supported)': '',
              'CLFSH (CLFLUSH instruction supported)': '',
              'CMOV (Conditional move instruction supported)': '',
              'CX8 (CMPXCHG8 instruction supported)': '',
              'DE (Debugging extension)': '',
              'FPU (Floating-point unit on-chip)': '',
              'FXSR (FXSAVE and FXSTOR instructions supported)': '',
              'HTT (Multi-threading)': '',
              'MCA (Machine check architecture)': '',
              'MCE (Machine check exception)': '',
              'MMX (MMX technology supported)': '',
              'MSR (Model specific registers)': '',
              'MTRR (Memory type range registers)': '',
              'PAE (Physical address extension)': '',
              'PAT (Page attribute table)': '',
              'PGE (Page global enable)': '',
              'PSE (Page size extension)': '',
              'PSE-36 (36-bit page size extension)': '',
              'SEP (Fast system call)': '',
              'SSE (Streaming SIMD extensions)': '',
              'SSE2 (Streaming SIMD extensions 2)': '',
              'TSC (Time stamp counter)': '',
              'VME (Virtual mode extension)': '',
            },
            ID: '12 0F A6 00 FF FB 8B 17',
            'L1 Cache Handle': '0x0023',
            'L2 Cache Handle': '0x0024',
            'L3 Cache Handle': '0x0025',
            Manufacturer: 'Advanced Micro Devices, Inc.',
            'Max Speed': '5850 MHz',
            'Part Number': 'Unknown',
            'Serial Number': 'Unknown',
            Signature: 'Family 25, Model 97, Stepping 2',
            'Socket Designation': 'CPU',
            Status: 'Populated, Enabled',
            'Thread Count': '32',
            Type: 'Central Processor',
            Upgrade: 'Other',
            Version: 'AMD Ryzen 9 7950X 16-Core Processor',
            Voltage: '1.3 V',
          },
          'SMBIOS 3.5.0 present.': '',
          'System Boot Information': {
            Status: 'No errors detected',
          },
          'System Information': {
            Family: 'Family',
            Manufacturer: 'Supermicro',
            'Product Name': 'AS -3015MR-H8TNR',
            'SKU Number': 'To be filled by O.E.M.',
            'Serial Number': 'S891412X4215167C',
            UUID: '00000000-0000-0000-0000-7cc25551d481',
            Version: '0123456789',
            'Wake-up Type': 'Power Switch',
          },
          'System Power Supply': {
            'Asset Tag': 'N/A',
            'Hot Replaceable': 'Yes',
            'Input Voltage Range Switching': 'Auto-switch',
            Location: 'PSU2',
            Manufacturer: 'SUPERMICRO',
            'Max Power Capacity': '2200 W',
            'Model Part Number': 'PWS-2K20A-1R',
            Name: 'PWS-2K20A-1R',
            Plugged: 'Yes',
            'Power Unit Group': '2',
            Revision: '2.5',
            'Serial Number': 'P2K20CN16LB3087',
            Status: 'Present, OK',
            Type: 'Switching',
          },
          'System Slot Information': {
            'Bus Address': '0000:02:00.0',
            Characteristics: {
              '3.3 V is provided': '',
              'Opening is shared': '',
              'PME signal is supported': '',
            },
            'Current Usage': 'In Use',
            Designation: 'M.2-C',
            ID: '3',
            Length: 'Long',
            Type: 'x2 PCI Express 5 x2',
          },
          'TPM Device': {
            Characteristics: {
              'Family configurable via platform software support': '',
            },
            Description: 'INFINEON',
            'Firmware Revision': '13.11',
            'OEM-specific Information': '0x00000000',
            'Specification Version': '2.0',
            'Vendor ID': 'IFX',
          },
          'Table at 0xA9231000.': '',
          'Unknown Type': {
            'Header and Data': {
              '2C 09 15 00 FF FF 01 01 00': '',
            },
          },
        },
        hostname: 'a3-0-2',
        serialNumber: 'S891412X4215167C',
      },
      '10.0.16.119': {
        cpuinfo: {
          lscpu: [
            {
              children: [
                {
                  data: '32-bit, 64-bit',
                  field: 'CPU op-mode(s):',
                },
                {
                  data: '48 bits physical, 48 bits virtual',
                  field: 'Address sizes:',
                },
                {
                  data: 'Little Endian',
                  field: 'Byte Order:',
                },
              ],
              data: 'x86_64',
              field: 'Architecture:',
            },
            {
              children: [
                {
                  data: '0-31',
                  field: 'On-line CPU(s) list:',
                },
              ],
              data: '32',
              field: 'CPU(s):',
            },
            {
              children: [
                {
                  data: 'Advanced Micro Devices, Inc.',
                  field: 'BIOS Vendor ID:',
                },
                {
                  children: [
                    {
                      data: 'AMD Ryzen 9 7950X 16-Core Processor             Unknown CPU @ 4.5GHz',
                      field: 'BIOS Model name:',
                    },
                    {
                      data: '107',
                      field: 'BIOS CPU family:',
                    },
                    {
                      data: '25',
                      field: 'CPU family:',
                    },
                    {
                      data: '97',
                      field: 'Model:',
                    },
                    {
                      data: '2',
                      field: 'Thread(s) per core:',
                    },
                    {
                      data: '16',
                      field: 'Core(s) per socket:',
                    },
                    {
                      data: '1',
                      field: 'Socket(s):',
                    },
                    {
                      data: '2',
                      field: 'Stepping:',
                    },
                    {
                      data: 'enabled',
                      field: 'Frequency boost:',
                    },
                    {
                      data: '64%',
                      field: 'CPU(s) scaling MHz:',
                    },
                    {
                      data: '5879.8818',
                      field: 'CPU max MHz:',
                    },
                    {
                      data: '3000.0000',
                      field: 'CPU min MHz:',
                    },
                    {
                      data: '8983.03',
                      field: 'BogoMIPS:',
                    },
                    {
                      data: 'fpu vme de pse tsc msr pae mce cx8 apic sep mtrr pge mca cmov pat pse36 clflush mmx fxsr sse sse2 ht syscall nx mmxext fxsr_opt pdpe1gb rdtscp lm constant_tsc rep_good amd_lbr_v2 nopl nonstop_tsc cpuid extd_apicid aperfmperf rapl pni pclmulqdq monitor ssse3 fma cx16 sse4_1 sse4_2 x2apic movbe popcnt aes xsave avx f16c rdrand lahf_lm cmp_legacy svm extapic cr8_legacy abm sse4a misalignsse 3dnowprefetch osvw ibs skinit wdt tce topoext perfctr_core perfctr_nb bpext perfctr_llc mwaitx cpb cat_l3 cdp_l3 hw_pstate ssbd mba perfmon_v2 ibrs ibpb stibp ibrs_enhanced vmmcall fsgsbase bmi1 avx2 smep bmi2 erms invpcid cqm rdt_a avx512f avx512dq rdseed adx smap avx512ifma clflushopt clwb avx512cd sha_ni avx512bw avx512vl xsaveopt xsavec xgetbv1 xsaves cqm_llc cqm_occup_llc cqm_mbm_total cqm_mbm_local avx512_bf16 clzero irperf xsaveerptr rdpru wbnoinvd cppc arat npt lbrv svm_lock nrip_save tsc_scale vmcb_clean flushbyasid decodeassists pausefilter pfthreshold avic v_vmsave_vmload vgif x2avic v_spec_ctrl avx512vbmi umip pku ospke avx512_vbmi2 gfni vaes vpclmulqdq avx512_vnni avx512_bitalg avx512_vpopcntdq rdpid overflow_recov succor smca fsrm flush_l1d amd_lbr_pmc_freeze',
                      field: 'Flags:',
                    },
                  ],
                  data: 'AMD Ryzen 9 7950X 16-Core Processor',
                  field: 'Model name:',
                },
              ],
              data: 'AuthenticAMD',
              field: 'Vendor ID:',
            },
            {
              children: [
                {
                  data: 'AMD-V',
                  field: 'Virtualization:',
                },
              ],
              data: null,
              field: 'Virtualization features:',
            },
            {
              children: [
                {
                  data: '512 KiB (16 instances)',
                  field: 'L1d:',
                },
                {
                  data: '512 KiB (16 instances)',
                  field: 'L1i:',
                },
                {
                  data: '16 MiB (16 instances)',
                  field: 'L2:',
                },
                {
                  data: '64 MiB (2 instances)',
                  field: 'L3:',
                },
              ],
              data: null,
              field: 'Caches (sum of all):',
            },
            {
              children: [
                {
                  data: '1',
                  field: 'NUMA node(s):',
                },
                {
                  data: '0-31',
                  field: 'NUMA node0 CPU(s):',
                },
              ],
              data: null,
              field: 'NUMA:',
            },
            {
              children: [
                {
                  data: 'Not affected',
                  field: 'Gather data sampling:',
                },
                {
                  data: 'Not affected',
                  field: 'Itlb multihit:',
                },
                {
                  data: 'Not affected',
                  field: 'L1tf:',
                },
                {
                  data: 'Not affected',
                  field: 'Mds:',
                },
                {
                  data: 'Not affected',
                  field: 'Meltdown:',
                },
                {
                  data: 'Not affected',
                  field: 'Mmio stale data:',
                },
                {
                  data: 'Not affected',
                  field: 'Reg file data sampling:',
                },
                {
                  data: 'Not affected',
                  field: 'Retbleed:',
                },
                {
                  data: 'Mitigation; safe RET, no microcode',
                  field: 'Spec rstack overflow:',
                },
                {
                  data: 'Mitigation; Speculative Store Bypass disabled via prctl',
                  field: 'Spec store bypass:',
                },
                {
                  data: 'Mitigation; usercopy/swapgs barriers and __user pointer sanitization',
                  field: 'Spectre v1:',
                },
                {
                  data: 'Mitigation; Enhanced / Automatic IBRS; IBPB conditional; STIBP always-on; RSB filling; PBRSB-eIBRS Not affected; BHI Not affected',
                  field: 'Spectre v2:',
                },
                {
                  data: 'Not affected',
                  field: 'Srbds:',
                },
                {
                  data: 'Not affected',
                  field: 'Tsx async abort:',
                },
              ],
              data: null,
              field: 'Vulnerabilities:',
            },
          ],
        },
        dmi: {
          '': '',
          '# dmidecode 3.4': '',
          '32-bit Memory Error Information': {
            'Device Address': 'Unknown',
            Granularity: 'Unknown',
            'Memory Array Address': 'Unknown',
            Operation: 'Unknown',
            Resolution: 'Unknown',
            Type: 'OK',
            'Vendor Syndrome': 'Unknown',
          },
          'Additional Information 1': {
            'Referenced Handle': '0x0014',
            'Referenced Offset': '0x00',
            String: 'Not Specified',
            Value: 'Unexpected size',
          },
          'BIOS Information': {
            Address: '0xF0000',
            'BIOS Revision': '5.26',
            Characteristics: {
              '3.5"/2.88 MB floppy services are supported (int 13h)': '',
              '3.5"/720 kB floppy services are supported (int 13h)': '',
              '5.25"/1.2 MB floppy services are supported (int 13h)': '',
              '5.25"/360 kB floppy services are supported (int 13h)': '',
              'ACPI is supported': '',
              'BIOS ROM is socketed': '',
              'BIOS boot specification is supported': '',
              'BIOS is upgradeable': '',
              'BIOS shadowing is allowed': '',
              'Boot from CD is supported': '',
              'CGA/mono video services are supported (int 10h)': '',
              'EDD is supported': '',
              'Japanese floppy for NEC 9800 1.2 MB is supported (int 13h)': '',
              'Japanese floppy for Toshiba 1.2 MB is supported (int 13h)': '',
              'PCI is supported': '',
              'Print screen service is supported (int 5h)': '',
              'Printer services are supported (int 17h)': '',
              'Selectable boot is supported': '',
              'Serial services are supported (int 14h)': '',
              'Targeted content distribution is supported': '',
              'UEFI is supported': '',
              'USB legacy is supported': '',
            },
            'ROM Size': '32 MB',
            'Release Date': '10/30/2023',
            'Runtime Size': '64 kB',
            Vendor: 'American Megatrends International, LLC.',
            Version: '1.1',
          },
          'Base Board Information': {
            'Asset Tag': 'To be filled by O.E.M.',
            'Chassis Handle': '0x0003',
            'Contained Object Handles': '0',
            Features: {
              'Board is a hosting board': '',
              'Board is replaceable': '',
            },
            'Location In Chassis': 'Node 1',
            Manufacturer: 'Supermicro',
            'Product Name': 'H13SRD-F',
            'Serial Number': 'OD23CS600380',
            Type: 'Motherboard',
            Version: '1.00',
          },
          'Cache Information': {
            Associativity: '16-way Set-associative',
            Configuration: 'Enabled, Not Socketed, Level 3',
            'Error Correction Type': 'Multi-bit ECC',
            'Installed SRAM Type': 'Pipeline Burst',
            'Installed Size': '64 MB',
            Location: 'Internal',
            'Maximum Size': '64 MB',
            'Operational Mode': 'Write Back',
            'Socket Designation': 'L3 Cache',
            Speed: '1 ns',
            'Supported SRAM Types': {
              'Pipeline Burst': '',
            },
            'System Type': 'Unified',
          },
          'Chassis Information': {
            'Asset Tag': 'Chassis Asset Tag',
            'Boot-up State': 'Safe',
            'Contained Elements': '0',
            Height: 'Unspecified',
            Lock: 'Not Present',
            Manufacturer: 'Supermicro',
            'Number Of Power Cords': '1',
            'OEM Information': '0x00000000',
            'Power Supply State': 'Safe',
            'SKU Number': 'To be filled by O.E.M.',
            'Security Status': 'None',
            'Serial Number': 'C9380AM52A20085',
            'Thermal State': 'Safe',
            Type: 'Other',
            Version: '0123456789',
          },
          'End Of Table': '',
          'Firmware Inventory Information': {
            'Associated Components': {
              '0x0016': '',
              _value: '1',
            },
            Characteristics: {
              Updatable: 'No',
              'Write-Protect': 'No',
            },
            'Firmware Component Name': 'BMC Firmware',
            'Firmware ID': 'Not Specified',
            'Firmware Version': '1.01',
            'Image Size': 'Unknown',
            'Lowest Supported Firmware Version': 'Not Specified',
            Manufacturer: '2A7C',
            'Release Date': 'Not Specified',
            State: 'Enabled',
          },
          'Getting SMBIOS data from sysfs.': '',
          'Handle 0x0000, DMI type 0, 26 bytes': '',
          'Handle 0x0001, DMI type 1, 27 bytes': '',
          'Handle 0x0002, DMI type 2, 15 bytes': '',
          'Handle 0x0003, DMI type 3, 22 bytes': '',
          'Handle 0x0004, DMI type 8, 9 bytes': '',
          'Handle 0x0005, DMI type 8, 9 bytes': '',
          'Handle 0x0006, DMI type 8, 9 bytes': '',
          'Handle 0x0007, DMI type 8, 9 bytes': '',
          'Handle 0x0008, DMI type 9, 17 bytes': '',
          'Handle 0x000A, DMI type 9, 17 bytes': '',
          'Handle 0x000B, DMI type 9, 17 bytes': '',
          'Handle 0x000C, DMI type 11, 5 bytes': '',
          'Handle 0x000D, DMI type 32, 20 bytes': '',
          'Handle 0x0012, DMI type 39, 22 bytes': '',
          'Handle 0x0013, DMI type 39, 22 bytes': '',
          'Handle 0x0014, DMI type 41, 11 bytes': '',
          'Handle 0x0015, DMI type 44, 9 bytes': '',
          'Handle 0x0016, DMI type 38, 18 bytes': '',
          'Handle 0x0017, DMI type 45, 26 bytes': '',
          'Handle 0x0022, DMI type 43, 31 bytes': '',
          'Handle 0x0023, DMI type 7, 27 bytes': '',
          'Handle 0x0024, DMI type 7, 27 bytes': '',
          'Handle 0x0025, DMI type 7, 27 bytes': '',
          'Handle 0x0026, DMI type 4, 48 bytes': '',
          'Handle 0x0027, DMI type 18, 23 bytes': '',
          'Handle 0x0028, DMI type 16, 23 bytes': '',
          'Handle 0x0029, DMI type 19, 31 bytes': '',
          'Handle 0x002A, DMI type 17, 92 bytes': '',
          'Handle 0x002B, DMI type 20, 35 bytes': '',
          'Handle 0x002C, DMI type 17, 92 bytes': '',
          'Handle 0x002D, DMI type 20, 35 bytes': '',
          'Handle 0x002E, DMI type 17, 92 bytes': '',
          'Handle 0x002F, DMI type 20, 35 bytes': '',
          'Handle 0x0030, DMI type 17, 92 bytes': '',
          'Handle 0x0031, DMI type 20, 35 bytes': '',
          'Handle 0x0032, DMI type 199, 5 bytes': '',
          'Handle 0x0033, DMI type 40, 203 bytes': '',
          'Handle 0x0034, DMI type 40, 203 bytes': '',
          'Handle 0x0035, DMI type 40, 27 bytes': '',
          'Handle 0x0036, DMI type 40, 27 bytes': '',
          'Handle 0x0037, DMI type 40, 27 bytes': '',
          'Handle 0x0038, DMI type 42, 122 bytes': '',
          'Handle 0x0039, DMI type 127, 4 bytes': '',
          'IPMI Device Information': {
            'Base Address': '0x0000000000000CA2 (I/O)',
            'I2C Slave Address': '0x10',
            'Interface Type': 'KCS (Keyboard Control Style)',
            'NV Storage Device': 'Not Present',
            'Register Spacing': 'Successive Byte Boundaries',
            'Specification Version': '2.0',
          },
          'Management Controller Host Interface': {
            'Device Type': 'USB',
            'Host Interface Type': 'Network',
            'Protocol ID': {
              'Host IP Address Format': 'IPv4',
              'Host IP Assignment Type': 'AutoConf',
              'IPv4 Address': '169.254.3.1',
              'IPv4 Mask': '255.255.255.0',
              'IPv4 Redfish Service Address': '169.254.3.254',
              'IPv4 Redfish Service Mask': '255.255.255.0',
              'Redfish Service Hostname': '169.254.3.254',
              'Redfish Service IP Address Format': 'IPv4',
              'Redfish Service IP Discovery Type': 'AutoConf',
              'Redfish Service Port': '443',
              'Redfish Service Vlan': '0',
              'Service UUID': 'c95cab2a-6de0-45f4-8b3b-726c3794b26b',
              _value: '04 (Redfish over IP)',
            },
            idProduct: '0x03ee',
            idVendor: '0x0b1f',
          },
          'Memory Array Mapped Address': {
            'Ending Address': '0x01FFFFFFFFF',
            'Partition Width': '4',
            'Physical Array Handle': '0x0028',
            'Range Size': '128 GB',
            'Starting Address': '0x00000000000',
          },
          'Memory Device': {
            'Array Handle': '0x0028',
            'Asset Tag': 'DIMMB2_AssetTag (date:23/52)',
            'Bank Locator': 'P0_Node0_Channel1_Dimm1',
            'Cache Size': 'None',
            'Configured Memory Speed': '3600 MT/s',
            'Configured Voltage': '1.1 V',
            'Data Width': '64 bits',
            'Error Information Handle': 'No Error',
            'Firmware Version': 'Unknown',
            'Form Factor': 'DIMM',
            Locator: 'DIMMB2',
            'Logical Size': 'None',
            Manufacturer: 'SK Hynix',
            'Maximum Voltage': '1.1 V',
            'Memory Operating Mode Capability': 'Volatile memory',
            'Memory Subsystem Controller Manufacturer ID': 'Unknown',
            'Memory Subsystem Controller Product ID': 'Unknown',
            'Memory Technology': 'DRAM',
            'Minimum Voltage': '1.1 V',
            'Module Manufacturer ID': 'Bank 1, Hex 0xAD',
            'Module Product ID': 'Unknown',
            'Non-Volatile Size': 'None',
            'Part Number': 'HMCG88MEBEA081N',
            Rank: '2',
            'Serial Number': '80AD012352469EB988',
            Set: 'None',
            Size: '32 GB',
            Speed: '4800 MT/s',
            'Total Width': '72 bits',
            Type: 'DDR5',
            'Type Detail': 'Synchronous Unbuffered (Unregistered)',
            'Volatile Size': '32 GB',
          },
          'Memory Device Mapped Address': {
            'Ending Address': '0x01FFFFFFFFF',
            'Interleave Position': 'Unknown',
            'Interleaved Data Depth': 'Unknown',
            'Memory Array Mapped Address Handle': '0x0029',
            'Partition Row Position': 'Unknown',
            'Physical Device Handle': '0x0030',
            'Range Size': '64 GB',
            'Starting Address': '0x01000000000',
          },
          'OEM Strings': {
            'String 1': 'AMD AM5',
            'String 2': 'Supermicro motherboard-H13 Series',
          },
          'OEM-specific Type': {
            'Header and Data': {
              'C7 05 32 00 01': '',
            },
            Strings: {
              '': '',
            },
          },
          'Onboard Device': {
            'Bus Address': '0000:06:00.0',
            'Reference Designation': 'ASPEED Video AST2600',
            Status: 'Enabled',
            Type: 'Video',
            'Type Instance': '1',
          },
          'Physical Memory Array': {
            'Error Correction Type': 'Other',
            'Error Information Handle': '0x0027',
            Location: 'System Board Or Motherboard',
            'Maximum Capacity': '128 GB',
            'Number Of Devices': '4',
            Use: 'System Memory',
          },
          'Port Connector Information': {
            'External Connector Type': 'USB Type-C Receptacle',
            'External Reference Designator': 'USB2(3.0)',
            'Internal Connector Type': 'None',
            'Internal Reference Designator': 'JUSB3P1',
            'Port Type': 'USB',
          },
          'Processor Information': {
            'Asset Tag': 'Unknown',
            Characteristics: {
              '64-bit capable': '',
              'Enhanced Virtualization': '',
              'Execute Protection': '',
              'Hardware Thread': '',
              'Multi-Core': '',
              'Power/Performance Control': '',
            },
            'Core Count': '16',
            'Core Enabled': '16',
            'Current Speed': '4500 MHz',
            'External Clock': '100 MHz',
            Family: 'Zen',
            Flags: {
              'APIC (On-chip APIC hardware supported)': '',
              'CLFSH (CLFLUSH instruction supported)': '',
              'CMOV (Conditional move instruction supported)': '',
              'CX8 (CMPXCHG8 instruction supported)': '',
              'DE (Debugging extension)': '',
              'FPU (Floating-point unit on-chip)': '',
              'FXSR (FXSAVE and FXSTOR instructions supported)': '',
              'HTT (Multi-threading)': '',
              'MCA (Machine check architecture)': '',
              'MCE (Machine check exception)': '',
              'MMX (MMX technology supported)': '',
              'MSR (Model specific registers)': '',
              'MTRR (Memory type range registers)': '',
              'PAE (Physical address extension)': '',
              'PAT (Page attribute table)': '',
              'PGE (Page global enable)': '',
              'PSE (Page size extension)': '',
              'PSE-36 (36-bit page size extension)': '',
              'SEP (Fast system call)': '',
              'SSE (Streaming SIMD extensions)': '',
              'SSE2 (Streaming SIMD extensions 2)': '',
              'TSC (Time stamp counter)': '',
              'VME (Virtual mode extension)': '',
            },
            ID: '12 0F A6 00 FF FB 8B 17',
            'L1 Cache Handle': '0x0023',
            'L2 Cache Handle': '0x0024',
            'L3 Cache Handle': '0x0025',
            Manufacturer: 'Advanced Micro Devices, Inc.',
            'Max Speed': '5850 MHz',
            'Part Number': 'Unknown',
            'Serial Number': 'Unknown',
            Signature: 'Family 25, Model 97, Stepping 2',
            'Socket Designation': 'CPU',
            Status: 'Populated, Enabled',
            'Thread Count': '32',
            Type: 'Central Processor',
            Upgrade: 'Other',
            Version: 'AMD Ryzen 9 7950X 16-Core Processor',
            Voltage: '1.3 V',
          },
          'SMBIOS 3.5.0 present.': '',
          'System Boot Information': {
            Status: 'No errors detected',
          },
          'System Information': {
            Family: 'Family',
            Manufacturer: 'Supermicro',
            'Product Name': 'AS -3015MR-H8TNR',
            'SKU Number': 'To be filled by O.E.M.',
            'Serial Number': 'S891412X4215176D',
            UUID: '00000000-0000-0000-0000-7cc25551b757',
            Version: '0123456789',
            'Wake-up Type': 'Power Switch',
          },
          'System Power Supply': {
            'Asset Tag': 'N/A',
            'Hot Replaceable': 'Yes',
            'Input Voltage Range Switching': 'Auto-switch',
            Location: 'PSU2',
            Manufacturer: 'SUPERMICRO',
            'Max Power Capacity': '2200 W',
            'Model Part Number': 'PWS-2K20A-1R',
            Name: 'PWS-2K20A-1R',
            Plugged: 'Yes',
            'Power Unit Group': '2',
            Revision: '2.5',
            'Serial Number': 'P2K20CN16LB2951',
            Status: 'Present, OK',
            Type: 'Switching',
          },
          'System Slot Information': {
            'Bus Address': '0000:02:00.0',
            Characteristics: {
              '3.3 V is provided': '',
              'Opening is shared': '',
              'PME signal is supported': '',
            },
            'Current Usage': 'In Use',
            Designation: 'M.2-C',
            ID: '3',
            Length: 'Long',
            Type: 'x2 PCI Express 5 x2',
          },
          'TPM Device': {
            Characteristics: {
              'Family configurable via platform software support': '',
            },
            Description: 'INFINEON',
            'Firmware Revision': '13.11',
            'OEM-specific Information': '0x00000000',
            'Specification Version': '2.0',
            'Vendor ID': 'IFX',
          },
          'Table at 0xA9231000.': '',
          'Unknown Type': {
            'Header and Data': {
              '2C 09 15 00 FF FF 01 01 00': '',
            },
          },
        },
        hostname: 'a4-0-3',
        serialNumber: 'S891412X4215176D',
      },
    },
    errors: {
      '10.0.20.10': 'error: could not fetch aerosol data',
    },
  },
  vminfo: {
    vmInfos: {
      '4260d8d0-d41b-469a-b400-603d5b1694eb': {
        instanceName: 'a4-0-1',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.101',
        flavor: 'm1.small',
        keyPair: '23211923791273921831',
        status: 'Active',
        task: 'None',
        powerState: 'On',
        age: 12341,
      },
      'c1cb485e-92ea-4cd0-925e-a058b5e71af7': {
        instanceName: 'a4-0-2',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.102',
        flavor: 'm1.small',
        keyPair: '2321192379127392183',
        status: 'Active',
        task: 'None',
        powerState: 'On',
        age: 12638267121,
      },
      '8c101a33-c9cf-417b-8a00-04ca07b59fe4': {
        instanceName: 'a4-0-3',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.103',
        flavor: 'm1.small',
        keyPair: '1232323',
        status: 'Inactive',
        task: 'None',
        powerState: 'On',
        age: 1231231,
      },
      '1a39f886-0553-4549-be98-b65077f641d8': {
        instanceName: 'a4-0-3',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.103',
        flavor: 'm1.small',
        keyPair: '1232323',
        status: 'Active',
        task: 'None',
        powerState: 'On',
        age: 1231231,
      },
      '06ef280b-955c-4ff8-bf13-96cb897fa2cb': {
        instanceName: 'a4-0-3',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.103',
        flavor: 'm1.small',
        keyPair: '1232323',
        status: 'Active',
        task: 'None',
        powerState: 'On',
        age: 1231231,
      },
      '802db516-d4f2-4456-948d-01d616eadec4': {
        instanceName: 'a4-0-3',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.103',
        flavor: 'm1.small',
        keyPair: '1232323',
        status: 'Inactive',
        task: 'None',
        powerState: 'On',
        age: 1231231,
      },
      'b2929acd-c503-4cdb-9a93-e3c079ad4f65': {
        instanceName: 'a4-0-3',
        imageName: 'centOS-9',
        ipAddress: '10.0.16.103',
        flavor: 'm1.small',
        keyPair: '1232323',
        status: 'Active',
        task: 'None',
        powerState: 'On',
        age: 1231231,
      },
    },
    errors: {
      'f2c0e291-647e-4611-a870-1794c51c995a': 'error: could not fetch vm data',
    },
  },
  pmusage: {
    pmUsageInfos: {
      '10.0.16.102': {
        freeDiskGB: 1717,
        freeRamMB: 122315,
        localGB: 1757,
        localGBUsed: 40,
        memoryMB: 127947,
        memoryMBUsed: 5632,
        runningVMs: 3,
        vcpus: 32,
        vcpusUsed: 6,
      },
      '10.0.16.119': {
        freeDiskGB: 1737,
        freeRamMB: 125899,
        localGB: 1757,
        localGBUsed: 20,
        memoryMB: 127947,
        memoryMBUsed: 2048,
        runningVMs: 2,
        vcpus: 32,
        vcpusUsed: 3,
      },
    },
    errors: {},
  },
};

export const mock_load1_data = {
  results: [
    {
      warnings: null,
      result: {
        streams: [
          {
            metric: 'node_load1{instance="a4-1-1:9100", job="node"}',
            values: [
              { timestamp: '2024-07-24T13:54:55.266Z', value: 0.16 },
              { timestamp: '2024-07-24T13:55:55.266Z', value: 0.17 },
              { timestamp: '2024-07-24T13:56:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T13:57:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T13:58:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T13:59:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:00:55.266Z', value: 0.24 },
              { timestamp: '2024-07-24T14:01:55.266Z', value: 0.38 },
              { timestamp: '2024-07-24T14:02:55.266Z', value: 0.18 },
              { timestamp: '2024-07-24T14:03:55.266Z', value: 0.2 },
              { timestamp: '2024-07-24T14:04:55.266Z', value: 0.22 },
              { timestamp: '2024-07-24T14:05:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:06:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:07:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:08:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:09:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:10:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:11:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:12:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:13:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:14:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:15:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:16:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:17:55.266Z', value: 0.11 },
              { timestamp: '2024-07-24T14:18:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:19:55.266Z', value: 0.36 },
              { timestamp: '2024-07-24T14:20:55.266Z', value: 0.98 },
              { timestamp: '2024-07-24T14:21:55.266Z', value: 1 },
              { timestamp: '2024-07-24T14:22:55.266Z', value: 0.4 },
              { timestamp: '2024-07-24T14:23:55.266Z', value: 0.18 },
              { timestamp: '2024-07-24T14:24:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T14:25:55.266Z', value: 0.11 },
              { timestamp: '2024-07-24T14:26:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:27:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:28:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:29:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:30:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:31:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:32:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:33:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:34:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:35:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:36:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:37:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:38:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:39:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:40:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:41:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:42:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:43:55.266Z', value: 0.32 },
              { timestamp: '2024-07-24T14:44:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T14:45:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:46:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:47:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:48:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:49:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:50:55.266Z', value: 0.11 },
              { timestamp: '2024-07-24T14:51:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:52:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:53:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:54:55.266Z', value: 0.03 },
            ],
          },
          {
            metric: 'node_load1{instance="a4-1-2:9100", job="node"}',
            values: [
              { timestamp: '2024-07-24T13:54:55.266Z', value: 0.2 },
              { timestamp: '2024-07-24T13:55:55.266Z', value: 0.23 },
              { timestamp: '2024-07-24T13:56:55.266Z', value: 0.73 },
              { timestamp: '2024-07-24T13:57:55.266Z', value: 0.39 },
              { timestamp: '2024-07-24T13:58:55.266Z', value: 0.3 },
              { timestamp: '2024-07-24T13:59:55.266Z', value: 0.11 },
              { timestamp: '2024-07-24T14:00:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:01:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:02:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:03:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:04:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:05:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:06:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:07:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:08:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:09:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:10:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:11:55.266Z', value: 0.24 },
              { timestamp: '2024-07-24T14:12:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:13:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:14:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:15:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:16:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:17:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:18:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:19:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:20:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:21:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:22:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:23:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:24:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:25:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:26:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:27:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:28:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:29:55.266Z', value: 0.2 },
              { timestamp: '2024-07-24T14:30:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:31:55.266Z', value: 0.17 },
              { timestamp: '2024-07-24T14:32:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T14:33:55.266Z', value: 0.14 },
              { timestamp: '2024-07-24T14:34:55.266Z', value: 0.23 },
              { timestamp: '2024-07-24T14:35:55.266Z', value: 0.16 },
              { timestamp: '2024-07-24T14:36:55.266Z', value: 0.15 },
              { timestamp: '2024-07-24T14:37:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:38:55.266Z', value: 0.17 },
              { timestamp: '2024-07-24T14:39:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T14:40:55.266Z', value: 0.32 },
              { timestamp: '2024-07-24T14:41:55.266Z', value: 0.17 },
              { timestamp: '2024-07-24T14:42:55.266Z', value: 0.18 },
              { timestamp: '2024-07-24T14:43:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:44:55.266Z', value: 0.16 },
              { timestamp: '2024-07-24T14:45:55.266Z', value: 0.14 },
              { timestamp: '2024-07-24T14:46:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T14:47:55.266Z', value: 0.29 },
              { timestamp: '2024-07-24T14:48:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:49:55.266Z', value: 0.25 },
              { timestamp: '2024-07-24T14:50:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:51:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:52:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:53:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:54:55.266Z', value: 0.2 },
            ],
          },
          {
            metric: 'node_load1{instance="a4-1-3:9100", job="node"}',
            values: [
              { timestamp: '2024-07-24T13:54:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T13:55:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T13:56:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T13:57:55.266Z', value: 0 },
              { timestamp: '2024-07-24T13:58:55.266Z', value: 0 },
              { timestamp: '2024-07-24T13:59:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:00:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:01:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:02:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:03:55.266Z', value: 0.3 },
              { timestamp: '2024-07-24T14:04:55.266Z', value: 1 },
              { timestamp: '2024-07-24T14:05:55.266Z', value: 1 },
              { timestamp: '2024-07-24T14:06:55.266Z', value: 0.41 },
              { timestamp: '2024-07-24T14:07:55.266Z', value: 0.15 },
              { timestamp: '2024-07-24T14:08:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:09:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:10:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:11:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:12:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:13:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:14:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:15:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:16:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:17:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:18:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:19:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:20:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:21:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:22:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:23:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:24:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:25:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:26:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:27:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:28:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:29:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:30:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:31:55.266Z', value: 0.2 },
              { timestamp: '2024-07-24T14:32:55.266Z', value: 0.15 },
              { timestamp: '2024-07-24T14:33:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:34:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:35:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:36:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:37:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:38:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:39:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:40:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:41:55.266Z', value: 0.05 },
              { timestamp: '2024-07-24T14:42:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:43:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:44:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:45:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:46:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:47:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:48:55.266Z', value: 0.08 },
              { timestamp: '2024-07-24T14:49:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:50:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:51:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:52:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:53:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:54:55.266Z', value: 0.04 },
            ],
          },
          {
            metric: 'node_load1{instance="a4-1-5:9100", job="node"}',
            values: [
              { timestamp: '2024-07-24T13:54:55.266Z', value: 0.17 },
              { timestamp: '2024-07-24T13:55:55.266Z', value: 0.17 },
              { timestamp: '2024-07-24T13:56:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T13:57:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T13:58:55.266Z', value: 0.23 },
              { timestamp: '2024-07-24T13:59:55.266Z', value: 0.16 },
              { timestamp: '2024-07-24T14:00:55.266Z', value: 0.14 },
              { timestamp: '2024-07-24T14:01:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:02:55.266Z', value: 0.18 },
              { timestamp: '2024-07-24T14:03:55.266Z', value: 0.31 },
              { timestamp: '2024-07-24T14:04:55.266Z', value: 0.19 },
              { timestamp: '2024-07-24T14:05:55.266Z', value: 0.15 },
              { timestamp: '2024-07-24T14:06:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:07:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:08:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:09:55.266Z', value: 0.29 },
              { timestamp: '2024-07-24T14:10:55.266Z', value: 0.39 },
              { timestamp: '2024-07-24T14:11:55.266Z', value: 0.44 },
              { timestamp: '2024-07-24T14:12:55.266Z', value: 0.36 },
              { timestamp: '2024-07-24T14:13:55.266Z', value: 0.13 },
              { timestamp: '2024-07-24T14:14:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:15:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:16:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:17:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:18:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:19:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:20:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:21:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:22:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:23:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:24:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:25:55.266Z', value: 0.18 },
              { timestamp: '2024-07-24T14:26:55.266Z', value: 0.06 },
              { timestamp: '2024-07-24T14:27:55.266Z', value: 0.02 },
              { timestamp: '2024-07-24T14:28:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:29:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:30:55.266Z', value: 0.15 },
              { timestamp: '2024-07-24T14:31:55.266Z', value: 0.52 },
              { timestamp: '2024-07-24T14:32:55.266Z', value: 0.19 },
              { timestamp: '2024-07-24T14:33:55.266Z', value: 0.12 },
              { timestamp: '2024-07-24T14:34:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:35:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:36:55.266Z', value: 0.07 },
              { timestamp: '2024-07-24T14:37:55.266Z', value: 0.2 },
              { timestamp: '2024-07-24T14:38:55.266Z', value: 0.14 },
              { timestamp: '2024-07-24T14:39:55.266Z', value: 0.11 },
              { timestamp: '2024-07-24T14:40:55.266Z', value: 0.04 },
              { timestamp: '2024-07-24T14:41:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:42:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:43:55.266Z', value: 0.03 },
              { timestamp: '2024-07-24T14:44:55.266Z', value: 0.01 },
              { timestamp: '2024-07-24T14:45:55.266Z', value: 0 },
              { timestamp: '2024-07-24T14:46:55.266Z', value: 0.09 },
              { timestamp: '2024-07-24T14:47:55.266Z', value: 0.21 },
              { timestamp: '2024-07-24T14:48:55.266Z', value: 0.14 },
              { timestamp: '2024-07-24T14:49:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:50:55.266Z', value: 0.1 },
              { timestamp: '2024-07-24T14:51:55.266Z', value: 0.25 },
              { timestamp: '2024-07-24T14:52:55.266Z', value: 0.53 },
              { timestamp: '2024-07-24T14:53:55.266Z', value: 1 },
              { timestamp: '2024-07-24T14:54:55.266Z', value: 0.71 },
            ],
          },
        ],
      },
    },
  ],
};
