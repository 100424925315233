import React, { useContext, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import styled from 'styled-components';
import { ThemeContext } from '../ThemeProvider';
import ListPage from '../routes/ListPage';
import OrderListPage from '../routes/OrderListPage';

const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.back_primary};
  color: ${({ theme }) => theme.text_secondary};
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.text_primary};

  &.Mui-selected {
    color: ${({ theme }) => theme.primary};
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function TopTabView() {
  const { theme } = useContext(ThemeContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledTabs value={value} onChange={handleChange} theme={theme} textColor="inherit">
        <StyledTab label="Deployed" theme={theme} {...a11yProps(0)} />
        <StyledTab label="Ordered" theme={theme} {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <ListPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrderListPage />
      </TabPanel>
    </>
  );
}
