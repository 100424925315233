import { createContext, useEffect, useState } from 'react';
import { darkTheme, lightTheme } from '../utils/theme';

export const ThemeContext = createContext(darkTheme);

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(darkTheme);
  const toggleTheme = () => {
    setCurrentTheme(currentTheme === darkTheme ? lightTheme : darkTheme);
  };

  useEffect(() => {
    document.body.style.backgroundColor = currentTheme.back_primary;
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
