import { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import FeedItem from '../cards/FeedItem';

function FeedPage() {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();

  return (
    <>
      <FeedItem />
    </>
  );
}

export default FeedPage;
