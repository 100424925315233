import { IconContainer } from '../styles';

const BackIcon = ({ theme }) => {
  return (
    <IconContainer>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m13.83 19a1 1 0 0 1 -.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28l-4.47 5.36 4.32 5.36a1 1 0 0 1 -.78 1.64z"
          fill={theme.text_primary}
        />
      </svg>
    </IconContainer>
  );
};

export default BackIcon;
