export function convertTime(nanoseconds) {
  const usPerMinute = 60 * 10**9;
  const usPerHour = usPerMinute * 60;
  const usPerDay = usPerHour * 24;

  if (nanoseconds >= usPerDay) {
    const days = Math.floor(nanoseconds / usPerDay);
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (nanoseconds >= usPerHour) {
    const hours = Math.floor(nanoseconds / usPerHour);
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (nanoseconds >= usPerMinute) {
    const minutes = Math.floor(nanoseconds / usPerMinute);
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `< 1 min`;
  }
}
