import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { drawer_width, navigationItems } from '../../constants/navigationItems';
import { darkTheme } from '../../utils/theme';
import DarkLogo from '../../logo_dark.png';
import LightLogo from '../../logo_light.png';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  ...(selected && {
    color: theme.primary,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Logo = styled('img')(() => ({
  width: 'auto',
  height: '80px',
}));

export default function DrawerLeft({ open, theme }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const uid = useSelector(state => state.user.uid);

  const handleNavChange = index => {
    if (navigationItems[index].label === 'Hardware' && uid !== '') {
      navigate(`/dashboard/${uid}`);
    } else {
      navigate(navigationItems[index].path);
    }
  };

  useEffect(() => {
    navigationItems.map((item, index) => {
      if (navigationItems[index].label === 'Hardware') {
        const itemParts = item.path.split('/', 2);
        const locationParts = location.pathname.split('/', 2);
        if (itemParts.length > 1 && locationParts.length > 1) {
          if (itemParts[1] === locationParts[1]) {
            setSelectedIndex(index);
          }
        }
      } else {
        if (item.path === location.pathname) {
          setSelectedIndex(index);
        }
      }
      return null;
    });
  }, [location]);

  return (
    <Drawer
      sx={{
        width: drawer_width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawer_width,
          backgroundColor: theme.back_primary,
          color: theme.text_primary,
          boxShadow: '0px 3px 6px rgba(0,0,0,0.16), 0px 3px 6px rgba(0,0,0,0.23)',
          borderTopRightRadius: '30px',
          borderBottomRightRadius: '30px',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}>
      <DrawerHeader>
        <Logo src={theme === darkTheme ? DarkLogo : LightLogo}></Logo>
      </DrawerHeader>
      <Divider />
      <List>
        {navigationItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <StyledListItemButton
              selected={selectedIndex === index}
              theme={theme}
              onClick={() => handleNavChange(index)}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 4,
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color: index === selectedIndex ? theme.primary : theme.text_secondary,
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
            </StyledListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
